import { debounce, useTheme } from '@moonpig/launchpad-utils'
import { useCallback, useEffect, useState } from 'react'

export function useIsMobile() {
  const DEBOUNCE_INTERVAL_TIME_MS = 250
  const AUTO_SCROLL_BREAKPOINT_KEY = 'md'

  const theme = useTheme()

  const [isMobileDevice, setIsMobileDevice] = useState(false)

  const isDesktop = useCallback(
    () =>
      window.matchMedia(
        `(min-width: ${theme.breakpoints.map[AUTO_SCROLL_BREAKPOINT_KEY]}px`,
      ).matches,
    [theme],
  )

  useEffect(() => {
    const onWindowResize = debounce(() => {
      setIsMobileDevice(!isDesktop())
    }, DEBOUNCE_INTERVAL_TIME_MS)
    onWindowResize()

    window.addEventListener('resize', onWindowResize)

    return () => window.removeEventListener('resize', onWindowResize)
  }, [isDesktop])

  return isMobileDevice
}
