import { getParentDepartment } from '@moonpig/web-core-utils'
import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'

export const getAllParentDepartments = (departments: DepartmentsEnum[]) => {
  const parentDepartments = departments.map(getParentDepartment)
  const uniqueParentDepartments = parentDepartments.filter(
    (department, index) => parentDepartments.indexOf(department) === index,
  )

  return uniqueParentDepartments
}
