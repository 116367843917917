import React, { forwardRef } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { CarouselItem, Chip } from '@moonpig/launchpad-components'
import { Filter } from '../../../services/types/services'
import { CAROUSEL_ITEM_HEIGHT_PX } from '../constants'
import { useFindLocaleText } from '../../../text-localisation'

const StyledCarouselItem = styled(CarouselItem)`
  height: ${CAROUSEL_ITEM_HEIGHT_PX};
  overflow: hidden;
`

type FilterChipProps = {
  filter: Filter
  applyNba: ({
    filter,
    select,
    source,
  }: {
    filter: Filter
    select: boolean
    source: 'carousel' | 'menu'
  }) => void
}

export const FilterChip = forwardRef<HTMLLIElement, FilterChipProps>(
  ({ filter, applyNba }, ref) => {
    const localiseText = useFindLocaleText()

    return (
      <StyledCarouselItem ref={ref}>
        <Chip
          dismissible
          selected={false}
          onClick={() => applyNba({ filter, select: true, source: 'carousel' })}
          aria-label={localiseText(
            'find.apply_facet_group_filter_facet_label',
            {
              group: filter.parent,
              label: filter.label.toLowerCase(),
            },
          )}
        >
          {filter.label}
        </Chip>
      </StyledCarouselItem>
    )
  },
)
