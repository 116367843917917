import { gql } from '@moonpig/web-core-graphql'

export const GetRecentlyViewedProductsGQL = gql`
  query GetRecentlyViewedProducts($productIds: [String]) {
    productLookup(productIds: $productIds) {
      products {
        id
        title
        category {
          id
          name
          department
        }
        isFavourited
        masterVariant {
          price {
            currencyCode
            centAmount
          }
          inStock
          masterImage {
            url
          }
        }
      }
    }
  }
`
