import React, { createContext, FC, PropsWithChildren } from 'react'

type ProductListingPageContext = [
  ProductListingPageState,
  React.Dispatch<ProductListingPageAction>,
]

export type ProductListingPageState = {
  loading: boolean
  initialOffset: number
  modified: boolean
}

type ActionPayloadSetLoading = boolean

type ActionPayloadReset = {
  initialOffset?: number
}

type ActionBeginLoadMore = {
  modified: boolean
}

type ActionLoadMore = number

type ActionSetModified = boolean

type ActionPayloads =
  | ActionPayloadSetLoading
  | ActionLoadMore
  | ActionPayloadReset
  | ActionBeginLoadMore
  | ActionSetModified

type ActionType =
  | 'SET_LOADING'
  | 'SET_MODIFIED'
  | 'BEGIN_LOAD_MORE'
  | 'END_LOAD_MORE'
  | 'RESET'

export type ProductListingPageAction = {
  type: ActionType
  payload: ActionPayloads
}

export const initialState: ProductListingPageState = {
  loading: false,
  initialOffset: 0,
  modified: false,
}

export const reducer = (
  state: ProductListingPageState,
  action: ProductListingPageAction,
): ProductListingPageState => {
  const { type, payload } = action
  switch (type) {
    case 'SET_LOADING': {
      return { ...state, loading: payload as ActionPayloadSetLoading }
    }
    case 'SET_MODIFIED':
      return {
        ...state,
        modified: payload as ActionSetModified,
      }
    case 'RESET': {
      const actionPayloadReset = payload as ActionPayloadReset
      return {
        ...state,
        ...actionPayloadReset,
      }
    }
    case 'BEGIN_LOAD_MORE':
      return {
        ...state,
        ...(payload as ActionBeginLoadMore),
      }
    case 'END_LOAD_MORE':
      return {
        ...state,
        modified: true,
      }
    /* istanbul ignore next */
    default:
      return state
  }
}

export const productListingPageContext =
  createContext<ProductListingPageContext>([
    initialState,
    /* istanbul ignore next */ () => {},
  ])

export const ProductListingPageProvider: FC<
  PropsWithChildren<{
    productListingContext: ProductListingPageContext
  }>
> = ({ productListingContext, children }) => (
  <productListingPageContext.Provider value={productListingContext}>
    {children}
  </productListingPageContext.Provider>
)

export const useProductListingPageContext = () =>
  React.useContext(productListingPageContext)
