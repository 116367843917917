import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { FIND_TEXT } from '../text-localisation/localeText'

type Props = {
  isCards: boolean
  localiseText: LocaleTextHook<typeof FIND_TEXT>
}

export const getSortByOptions = ({ isCards, localiseText }: Props) => {
  const sortByOptions = [
    { label: localiseText('find.popularity'), value: 'POPULARITY' },
    {
      label: localiseText('find.newness'),
      value: 'NEWNESS',
    },
  ]

  if (!isCards) {
    sortByOptions.push(
      ...[
        {
          label: localiseText('find.price_high'),
          value: 'PRICE_DESCENDING',
        },
        {
          label: localiseText('find.price_low'),
          value: 'PRICE_ASCENDING',
        },
      ],
    )
  }

  return sortByOptions
}
