import { ProductTileProduct } from '../ProductTile'
import { AD_CELL_WIDTH, AD_CELL_WIDTH_WIDE } from '../PromoTile/constants'
import { ProductGridComponent, ProductGridItem } from './types'

const promoTile = {
  component: ProductGridComponent.promoTile,
  id: ProductGridComponent.promoTile,
}

export const formatGridLayout = (
  products: ProductTileProduct[],
  promoTileLocation: number,
) => {
  if (!products || products.length === 0) return []

  if (!promoTileLocation) return products

  return products.reduce(
    (
      acc: ProductGridItem[],
      product: ProductTileProduct | ProductGridItem,
      currentIndex: number,
    ) => {
      let items: ProductGridItem[] = [...acc]

      if (promoTileLocation && currentIndex === promoTileLocation) {
        items = [...items, promoTile]
      }

      items = [
        ...items,
        {
          ...product,
        },
      ]

      return items
    },
    [],
  )
}

export const calculateComponentWidth = (
  component: string,
  numOfTilesPerRow: number,
) => {
  switch (component) {
    case ProductGridComponent.promoTile:
      if (numOfTilesPerRow === AD_CELL_WIDTH_WIDE) return `100%`
      return AD_CELL_WIDTH / numOfTilesPerRow
    case ProductGridComponent.giftXSellCarousel:
      return `100%`
    default:
      return 1 / numOfTilesPerRow
  }
}
