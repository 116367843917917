import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'
import { DepartmentCount } from '../types/services'
import { SearchServiceProductSearchQuery } from './__generated__/query'

type ExtractDepartmentCountsProps = {
  departments: DepartmentsEnum[]
  data: SearchServiceProductSearchQuery
}

export const extractDepartmentCounts: (
  props: ExtractDepartmentCountsProps,
) => DepartmentCount[] = ({ departments, data }) => {
  return departments.map((x, i) => {
    return {
      department: x,
      count:
        i === 0
          ? data?.secondProductSearch?.count || 0
          : data?.thirdProductSearch?.count || 0,
    }
  })
}
