import { SearchFiltersFacet } from '../../pages/GalleryPage/types'
import {
  FilterItem,
  FilterCategory,
  ApplicableFilter,
} from '../../services/types/services'
import { filtersAreEqual } from './filtersAreEqual'

export const reduceFilters = (
  items: FilterItem[],
): (ApplicableFilter | FilterCategory)[] => {
  return items.reduce<(ApplicableFilter | FilterCategory)[]>(
    (accumulator, current) => {
      return 'id' in current ? [...accumulator, current] : accumulator
    },
    [],
  )
}

export const removeDuplicateFilters = (
  facets: SearchFiltersFacet[],
): SearchFiltersFacet[] => {
  if (!facets || facets.length === 0) {
    return facets
  }
  return facets.filter(
    (facet, index, initialFacets) =>
      index ===
      initialFacets.findIndex(initialFacet =>
        filtersAreEqual(facet, initialFacet),
      ),
  )
}
