import { useContext, useEffect, useState } from 'react'
import { productListingPageContext } from '../../utils/productListingPageContext'
import { GALLERY_PAGE_SIZE } from '../../constants'
import { setOffsetAndProductIdInUrl } from './setOffsetAndProductIdInUrl'

const setPageOffset = setOffsetAndProductIdInUrl as (offset: number) => void

export const usePagingOffset = ({ loaded }: { loaded: boolean }) => {
  const [{ initialOffset }] = useContext(productListingPageContext)
  const [currentPageNumber, setCurrentPageNumber] = useState(
    initialOffset / GALLERY_PAGE_SIZE + 1,
  )

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [initialOffset])

  const offsetBorderApproaching = (productIndex: number) => {
    /* istanbul ignore next */
    if (loaded) {
      setPageOffset(productIndex)
      setCurrentPageNumber(productIndex / GALLERY_PAGE_SIZE + 1)
    }
  }

  return { currentPageNumber, offsetBorderApproaching }
}
