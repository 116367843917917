import { GALLERY_PAGE_SIZE } from '../../../constants'

const LOADING_INDICATOR_HIGHT = 50
export const scrollPageDown = (
  gridElement: HTMLElement,
  productsCount: number,
) => {
  const { scrollHeight } = gridElement
  const pageCount = productsCount / GALLERY_PAGE_SIZE
  const pageHeight = scrollHeight / pageCount
  window.scrollBy(0, pageHeight - LOADING_INDICATOR_HIGHT)
}
