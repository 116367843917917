import React, { FC } from 'react'
import { RecentlyViewedProducts } from './RecentlyViewedProducts'

type RecentlyViewedProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  pageTitle: string
}

export const RecentlyViewed: FC<RecentlyViewedProps> = ({
  isOpen,
  onClose,
  onOpen,
  pageTitle,
}) => (
  <RecentlyViewedProducts
    pageTitle={pageTitle}
    isOpen={isOpen}
    onClose={onClose}
    onOpen={onOpen}
  />
)
