import React from 'react'
import { Item } from '@radix-ui/react-dropdown-menu'
import { Box, Flex } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  ApplicableFilter,
  FilterCategory,
  FilterItem,
} from '../../../services/types/services'
import { FilterSource } from '../../../types'
import { mapFilterItem } from '../../../utils/GalleryFiltering/mappings'
import { shouldShowClearButton } from '../../../utils/GalleryFiltering/shouldShowClearButton'
import { ClearButton } from '../../ClearButton'

const StyledFooterContainer = styled(Box)`
  ${s({
    bgcolor: 'colorBackground01',
    borderTop: 1,
    borderColor: 'colorBorder03',
    position: 'sticky',
    bottom: 0,
  })}
`

type Props = {
  filterCategory: FilterCategory
  selectedFilters: ApplicableFilter[]
  isMobile: boolean
  resultsButton: JSX.Element
}

export const createMenuFooter = ({
  filterCategory,
  selectedFilters,
  isMobile,
  resultsButton,
}: Props) => {
  const showClearButton = shouldShowClearButton({
    category: filterCategory,
    selectedFilters,
  })

  return isMobile ? (
    <StyledFooterContainer>{resultsButton}</StyledFooterContainer>
  ) : (
    <StyledFooterContainer display="flex" alignItems="center">
      {showClearButton && (
        <Flex width={1 / 3} justifyContent="center" alignItems="center">
          <ClearButton
            level={1}
            category={filterCategory}
            source={FilterSource.QuickFilters}
            hide={false}
          />
        </Flex>
      )}
      <Flex
        width={showClearButton ? 2 / 3 : 1}
        justifyContent="center"
        alignItems="center"
      >
        {resultsButton}
      </Flex>
    </StyledFooterContainer>
  )
}

export const createMenuItems = (
  filterItems: FilterItem[],
  toggleFilter: ({
    filter,
    select,
    source,
  }: {
    filter: ApplicableFilter
    select: boolean
    source: FilterSource
  }) => void,
  isMobile: boolean,
) => {
  const ItemElement: React.ElementType = isMobile ? Box : Item

  return filterItems.map(filterItem => {
    if (filterItem.__typename === 'Filter') {
      return (
        <ItemElement
          key={filterItem.id || /* istanbul ignore next */ filterItem.label}
          onSelect={(e: Event) => e.preventDefault()}
        >
          {mapFilterItem({
            filterItem,
            onClick: () => {
              toggleFilter({
                filter: filterItem,
                select: !filterItem.isSelected,
                source: FilterSource.QuickFilters,
              })
            },
          })}
        </ItemElement>
      )
    }

    if (filterItem.__typename === 'FilterHeader') {
      return (
        <ItemElement key={filterItem.label} disabled>
          {mapFilterItem({
            filterItem,
            onClick: /* istanbul ignore next */ () => {},
          })}
        </ItemElement>
      )
    }

    return null
  })
}
