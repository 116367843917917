import { SortOrder } from '@moonpig/web-explore-types-graphql'

export const getTrackingLabelForSortOrder = (sortOrder: string) => {
  switch (sortOrder) {
    case SortOrder.POPULARITY:
      // append 'default' to the default sort order
      return 'popularity - default'
    case SortOrder.PRICE_ASCENDING:
      return 'price ascending'
    case SortOrder.PRICE_DESCENDING:
      return 'price descending'
    default:
      return sortOrder
  }
}
