import { gql } from '@moonpig/web-core-graphql'
import { OperationVariables } from '@apollo/client'
import { AppContext } from '../types'
import {
  FavouriteProduct,
  LoadFavouritesRequest,
  LoadFavouritesRequestContext,
  LoadFavouritesResponse,
} from '../types/services'
import { GetFavouritesListServiceQuery } from './__generated__/createLoadFavourites'
import { runGraphQLQuery } from '../helpers/runGraphQLQuery'

export const GetFavouritesListServiceGQL = gql`
  query GetFavouritesListService {
    me {
      customer {
        id
        favourites {
          list {
            id
            name
            products {
              id
            }
          }
        }
      }
    }
  }
`

const loadFavourites: (
  context: AppContext,
  requestContext: LoadFavouritesRequestContext,
) => Promise<LoadFavouritesResponse> = async ({ client }, { loggedIn }) => {
  if (!loggedIn) {
    return { favourites: [] }
  }

  const result = await runGraphQLQuery<
    GetFavouritesListServiceQuery,
    OperationVariables,
    {
      favourites: FavouriteProduct[]
    }
  >({
    client,
    query: GetFavouritesListServiceGQL,
    variables: {},
    extractResult: data => {
      return {
        favourites:
          data.me.customer?.favourites.list.products
            .map(x => {
              return { id: x?.id || '' }
            })
            .filter(x => x.id) || ([] as FavouriteProduct[]),
      }
    },
  })

  return result
}

export const createLoadFavourites: (
  context: AppContext,
) => LoadFavouritesRequest = context => {
  return async (requestContext: LoadFavouritesRequestContext) => {
    return loadFavourites(context, requestContext)
  }
}
