import { reduceFilters } from './FilteringUtils/filterItemUtils'
import {
  FilterItem,
  Filter,
  FilterCategory,
  ApplicableFilter,
} from '../services/types/services'

export const useNbaFilters = (filters: FilterItem[]): Filter[] => {
  const recursiveFlatMapNbas = (
    allFilters: (ApplicableFilter | FilterCategory)[],
  ): Filter[] => {
    if (allFilters.length === 0) {
      return []
    }

    const current = allFilters
      .filter(
        x => x.__typename === 'Filter' || x.__typename === 'FilterCategory',
      )
      .map(x => {
        if (x.__typename === 'FilterCategory') {
          return {
            id: x.id,
            label: x.label,
            parent: x.parent,
            nbaScore: x.nbaScore,
            count: x.count,
          }
        }

        return x
      })
    const currentChildren = allFilters.flatMap(x =>
      x.__typename === 'FilterCategory'
        ? recursiveFlatMapNbas(reduceFilters(x.children))
        : [],
    )

    return [...current, ...currentChildren] as Filter[]
  }

  const nbaFilters = [
    ...new Set(recursiveFlatMapNbas(reduceFilters(filters))),
  ].filter(x => x.nbaScore && x.nbaScore !== null)

  return [...nbaFilters]
}
