import { useState } from 'react'
import { LoadMoreType, PageLoadState } from '../types'

type LoadMoreProductsType = (loadMoreType: LoadMoreType) => Promise<boolean>

export const useLoadPage = ({
  loadMoreProducts,
}: {
  loadMoreProducts: LoadMoreProductsType
}) => {
  const [loaded, setLoaded] = useState(true)
  const [loadState, setLoadState] = useState(PageLoadState.Loaded)

  const loadPage = async (
    loadMoreType: LoadMoreType,
  ): Promise<PageLoadState> => {
    try {
      if (await loadMoreProducts(loadMoreType)) {
        return PageLoadState.Loaded
      }
      return loadMoreType === LoadMoreType.CLICK ||
        loadMoreType === LoadMoreType.SCROLL
        ? PageLoadState.LoadingNext
        : PageLoadState.LoadingPrev
    } catch (e) {
      /* istanbul ignore next */
      return PageLoadState.Error
    }
  }

  const loadCallback = async (loadMoreType: LoadMoreType) => {
    setLoaded(false)
    const isPreviousPage =
      loadMoreType === LoadMoreType.SCROLL_PREV ||
      loadMoreType === LoadMoreType.CLICK_PREV

    setLoadState(
      isPreviousPage ? PageLoadState.LoadingPrev : PageLoadState.LoadingNext,
    )
    const loadPageState = await loadPage(loadMoreType)
    setLoadState(loadPageState)
    setLoaded(true)
    return loadPageState
  }

  return { loadCallback, loadState, loaded }
}
