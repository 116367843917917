import { useRecentlyViewedState } from '@moonpig/web-shared-products'
import { useEffect } from 'react'
import { GetRecentlyViewedProductsGQL } from '../queries/getRecentlyViewedProducts'
import {
  GetRecentlyViewedProductsQuery,
  GetRecentlyViewedProductsQueryVariables,
} from '../queries/__generated__/GetRecentlyViewedProducts'
import { RecentlyViewedProductEnriched } from '../types'
import { useLazyQuery } from './graphql'

export const useRecentlyViewedProducts =
  (): RecentlyViewedProductEnriched[] => {
    const { recentlyViewedProducts } = useRecentlyViewedState()
    const productIds = recentlyViewedProducts.map(rvp => rvp.id)
    const [query, { data, called }] = useLazyQuery<
      GetRecentlyViewedProductsQuery,
      GetRecentlyViewedProductsQueryVariables
    >(GetRecentlyViewedProductsGQL, {
      ssr: false,
      variables: {
        productIds,
      },
    })

    useEffect(() => {
      if (productIds.length > 0 && !called) {
        query()
      }
    }, [productIds, query, called])

    const recentlyViewedProductsEnriched = recentlyViewedProducts
      .map(rvp => {
        const product = data?.productLookup?.products?.find(
          ({ id }) => id === rvp.id,
        )

        if (!product) {
          return null
        }

        return {
          id: rvp.id,
          title: product.title,
          image: product.masterVariant.masterImage.url,
          url: rvp.url,
          masterVariant: {
            title: product.title,
            price: {
              currencyCode: product.masterVariant.price.currencyCode,
              centAmount: product.masterVariant.price.centAmount,
            },
            inStock: product.masterVariant.inStock,
          },
          isFavourite: product.isFavourited,
          category: {
            name: product.category.name,
            department: product.category.department,
          },
        }
      })
      .filter(rvp => !!rvp) as RecentlyViewedProductEnriched[]

    return recentlyViewedProductsEnriched
  }
