import { SearchProduct } from '../../services'

export const removeMoonpigPlusDiscountPill = (products: SearchProduct[]) => {
  return products.map(product => {
    if (product.primaryProductPill?.displayLabel === '50% off with Plus') {
      return {
        ...product,
        primaryProductPill: undefined,
      }
    }
    /* istanbul ignore next */
    return product
  })
}
