import React, { FC, useCallback } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Box, SideModal } from '@moonpig/launchpad-components'
import { SortOrder } from '@moonpig/web-explore-types-graphql'
import {
  DESKTOP_MODAL_FILTERS_MENU_WIDTH,
  FILTERS_MENU_OFFSET,
  FILTERS_MENU_RESULTS_CTA_CONTAINER_HEIGHT,
} from '../../constants'
import {
  Header,
  SlidingMenu,
  FilterCategorySubMenu,
  SortBy,
  ResultsButton,
} from './components'
import { FilterItem } from '../../services/types/services'
import { mapFilterItem } from '../../utils/GalleryFiltering/mappings'
import { useSearchStore } from '../../store/SearchStore'
import { useFindLocaleText } from '../../text-localisation'
import { FilterSource } from '../../types'
import { shouldShowClearButton } from '../../utils/GalleryFiltering/shouldShowClearButton'

const StyledOffsetContainer = styled(Box)`
  margin-top: ${FILTERS_MENU_OFFSET}px;
  height: 100%;
  max-height: calc(
    100vh - ${FILTERS_MENU_OFFSET + FILTERS_MENU_RESULTS_CTA_CONTAINER_HEIGHT}px
  );
  overflow: hidden auto;
  overscroll-behavior: contain;

  @supports (max-height: 100dvh) {
    max-height: calc(
      100dvh -
        ${FILTERS_MENU_OFFSET + FILTERS_MENU_RESULTS_CTA_CONTAINER_HEIGHT}px
    );
  }
`

const StyledScrollableArea = styled(Box)`
  position: relative;
  overflow: hidden auto;
  overscroll-behavior: contain;
  min-height: calc(
    100vh - 64px - ${FILTERS_MENU_RESULTS_CTA_CONTAINER_HEIGHT}px
  );

  @supports (min-height: 100dvh) {
    min-height: calc(
      100dvh - 64px - ${FILTERS_MENU_RESULTS_CTA_CONTAINER_HEIGHT}px
    );
  }
`

type FiltersMenuProps = {
  sortOptions: { label: string; value: string }[]
}

export const FiltersMenu: FC<FiltersMenuProps> = ({ sortOptions }) => {
  const localiseText = useFindLocaleText()
  const isOpen = useSearchStore(store => store.filtersMenuOpen)
  const filters = useSearchStore(store => store.filters)
  const toggleFiltersMenu: () => void = useSearchStore(
    store => store.toggleFiltersMenu,
  )
  const toggleFiltersCategory = useSearchStore(
    store => store.toggleFiltersCategory,
  )
  const categories = useSearchStore(store => store.selectedCategories)
  const toggleFilter = useSearchStore(store => store.toggleFilter)
  const toggleAllCategoryFilters = useSearchStore(
    store => store.toggleAllCategoryFilters,
  )
  const resultsLoading = useSearchStore(store => store.resultsLoading)
  const resultsCount = useSearchStore(store => store.results.count)
  const sortBy = useSearchStore(store => store.sortBy)
  const sortValue = useSearchStore(store => store.sortValue)
  const defaultSortValue = useSearchStore(store => store.initialSortValue)
  const sortMenuOpen = useSearchStore(store => store.sortMenuOpen)
  const selectedFilters = useSearchStore(store => store.selectedFilters)

  const hideTopLevelClearButton = !shouldShowClearButton({
    selectedFilters,
    sortValue,
    defaultSortValue,
  })

  const onFilterItemClick = useCallback(
    (filterItem: FilterItem, select?: boolean) => {
      switch (filterItem.__typename) {
        case 'FilterCategory':
          toggleFiltersCategory(filterItem)
          break
        case 'Filter':
          toggleFilter({
            filter: filterItem,
            select: !!select,
            source: FilterSource.FiltersMenu,
          })
          break
        case 'AllFilter':
          toggleAllCategoryFilters({
            filter: filterItem,
            select: !!select,
            source: FilterSource.FiltersMenu,
          })
          break
        case 'FilterToggle':
        case 'FilterInvertedToggle':
          toggleFilter({
            filter: filterItem,
            select: !!select,
            source: FilterSource.Toggle,
          })
          break
      }
    },
    [toggleAllCategoryFilters, toggleFilter, toggleFiltersCategory],
  )

  const onSortByChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      sortBy(event.target.value as SortOrder)
    },
    [sortBy],
  )

  const ResultsCTA = (
    <Box borderTop={1} borderColor="colorBorder03">
      <ResultsButton
        onClick={toggleFiltersMenu}
        loading={resultsLoading}
        resultsCount={resultsCount}
      />
    </Box>
  )

  return isOpen ? (
    <SideModal
      onDismiss={toggleFiltersMenu}
      isOpen={isOpen}
      description={localiseText('find.filter_menu')}
      side="left"
      maxWidth={{ md: DESKTOP_MODAL_FILTERS_MENU_WIDTH }}
      minWidth={{ md: DESKTOP_MODAL_FILTERS_MENU_WIDTH }}
    >
      <SlidingMenu>
        <Header
          onClose={toggleFiltersMenu}
          hideClearButton={hideTopLevelClearButton}
        />
        <StyledOffsetContainer>
          <SortBy
            options={sortOptions}
            value={sortValue}
            onChange={onSortByChange}
            defaultValue={defaultSortValue}
            open={sortMenuOpen}
            resultsButton={ResultsCTA}
          />
          {filters.map(filterItem =>
            mapFilterItem({ filterItem, onClick: onFilterItemClick }),
          )}
        </StyledOffsetContainer>

        {categories.map((category, index) => (
          <FilterCategorySubMenu
            key={`${category.id}-sub-menu`}
            category={category}
            level={index + 1}
            onClose={() => {
              toggleFiltersCategory(category)
            }}
            hideClearButton={
              !shouldShowClearButton({
                category,
                selectedFilters,
              })
            }
          >
            <StyledScrollableArea>
              {category.children.map(filterItem =>
                mapFilterItem({
                  filterItem,
                  onClick: onFilterItemClick,
                  level: index + 1,
                }),
              )}
            </StyledScrollableArea>
            {ResultsCTA}
          </FilterCategorySubMenu>
        ))}
        {ResultsCTA}
      </SlidingMenu>
    </SideModal>
  ) : null
}
