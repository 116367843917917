import React, { FC } from 'react'
import { ClearButton as ClearBtn } from '../../ClearButton'
import { shouldShowClearButton } from '../../../utils/GalleryFiltering/shouldShowClearButton'
import {
  ApplicableFilter,
  FilterCategory,
} from '../../../services/types/services'
import { FilterSource } from '../../../types'

type Props = {
  filterCategory: FilterCategory
  selectedFilters: ApplicableFilter[]
}

export const ClearButton: FC<Props> = ({ filterCategory, selectedFilters }) => {
  return (
    <ClearBtn
      level={1}
      category={filterCategory}
      hide={
        !shouldShowClearButton({
          category: filterCategory,
          selectedFilters,
        })
      }
      source={FilterSource.QuickFilters}
    />
  )
}
