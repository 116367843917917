import { useMemo } from 'react'
import { SearchFilter } from '../../services'
import { SelectedSuggestion } from '../../components/types'

export const useFiltersWithSuggestion = ({
  filters,
  selectedSuggestion,
}: {
  filters: SearchFilter[]
  selectedSuggestion?: SelectedSuggestion
}) => {
  return useMemo(() => {
    return selectedSuggestion?.facetKey
      ? [
          ...filters,
          {
            group: selectedSuggestion.group,
            key: selectedSuggestion.facetKey,
          },
        ]
      : filters
  }, [filters, selectedSuggestion])
}
