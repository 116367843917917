import React, { FC } from 'react'
import {
  LoadingIndicator,
  Text,
  Flex,
  SecondaryButton,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useFindLocaleText } from '../../text-localisation'

const StyledLoadMoreButton = styled(SecondaryButton)`
  ${s({
    width: { xs: '100%', md: 'auto' },
    mt: 6,
    mb: 4,
    px: { xs: 0, md: 6, lg: 13 },
    bgcolor: 'colorBackground01',
    color: 'colorInteractionIcon',
    borderColor: 'colorInteractionIcon',
  })}

  &:not(:disabled) {
    &:hover,
    &:focus {
      ${s({
        bgcolor: 'colorBackground01',
        color: 'colorInteractionIcon',
        borderColor: 'colorInteractionIcon',
      })}
    }
  }
`

type LoaderProps = {
  loading: boolean
  showLoadButton: boolean
  loadMoreClicked: () => void
}

export const Loader: FC<LoaderProps> = ({
  loading,
  showLoadButton,
  loadMoreClicked,
}) => {
  const localiseText = useFindLocaleText()
  return (
    <Flex justifyContent="center" alignItems="center">
      {!loading && showLoadButton && (
        <StyledLoadMoreButton
          aria-label={localiseText('find.load_more')}
          onClick={loadMoreClicked}
        >
          {localiseText('find.load_more')}
        </StyledLoadMoreButton>
      )}
      {loading && (
        <>
          <LoadingIndicator
            role="status"
            label={localiseText('find.loading_more')}
          />
          <Text
            ml={4}
            aria-label={localiseText('find.loading_more')}
            aria-hidden
          >
            {localiseText('find.loading_more')}&hellip;
          </Text>
        </>
      )}
    </Flex>
  )
}
