import React, { createContext, FC, PropsWithChildren, useContext } from 'react'
import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'
import { SelectedSuggestion } from '../components/types'

type SearchContextType = {
  departments: DepartmentsEnum[]
  searchTerm: string
  presetFacets?: {
    group: string
    facetKey: string
  }[]
  selectedSuggestion?: SelectedSuggestion
}

const searchContext = createContext<SearchContextType>({
  departments: [],
  searchTerm: '',
})

export const SearchContextProvider: FC<
  PropsWithChildren<SearchContextType>
> = ({ children, ...props }) => (
  <searchContext.Provider value={props}>{children}</searchContext.Provider>
)

export const useSearchContext = (): SearchContextType => {
  return useContext(searchContext)
}
