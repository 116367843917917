import { AllFilter, FilterCategory } from '../../services/types/services'

type Props = {
  allCategoryFilters: string[]
  select: boolean
  category: FilterCategory
}

export const updateAllFilters = ({
  allCategoryFilters,
  select,
  category,
}: Props) => {
  if (!category) {
    return []
  }

  const allFilterId = (
    category.children.find(f => f.__typename === 'AllFilter') as AllFilter
  )?.id

  if (!select) {
    return allCategoryFilters.filter(f => f !== allFilterId)
  }

  const areUnselectedFilters = category.children.some(
    child => child.__typename === 'Filter' && !child.isSelected,
  )

  if (areUnselectedFilters) {
    return allCategoryFilters
  }

  return allFilterId
    ? [...allCategoryFilters, allFilterId]
    : /* istanbul ignore next */ allCategoryFilters
}
