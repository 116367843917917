import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Box, PrimaryButton } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { useFindLocaleText } from '../../../../text-localisation'

const StyledViewResultsButton = styled(PrimaryButton)`
  width: 100%;
`

const StyledResultsCtaWrapper = styled(Box)`
  ${s({
    position: 'sticky',
    bottom: 0,
    left: 0,
    padding: 6,
    width: '100%',
    bgcolor: 'colorBackground01',
  })}
`

type Props = {
  onClick: () => void
  loading: boolean
  resultsCount: number
}

export const ResultsButton: FC<Props> = ({
  onClick,
  loading,
  resultsCount,
}) => {
  const localiseText = useFindLocaleText()

  return (
    <StyledResultsCtaWrapper>
      <StyledViewResultsButton onClick={onClick} loading={loading}>
        {localiseText('find.view_n_results', { resultsCount })}
      </StyledViewResultsButton>
    </StyledResultsCtaWrapper>
  )
}
