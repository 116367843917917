import { useRouter } from '@moonpig/web-core-routing'
import { useEffect } from 'react'
import { useProductListingPageContext } from './productListingPageContext'

export const useResetProductListing = (offset: number) => {
  const { onRouteChangeStart } = useRouter()
  const [{ initialOffset: currentInitialOffset }, dispatch] =
    useProductListingPageContext()

  useEffect(
    () =>
      onRouteChangeStart(() => {
        if (offset !== currentInitialOffset) {
          dispatch({
            type: 'RESET',
            payload: {
              initialOffset: offset,
            },
          })
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, onRouteChangeStart],
  )
}
