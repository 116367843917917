export const firstToCapitalOtherToLower = (phrase: string) => {
  return phrase
    .split(' ')
    .map((word, id) => {
      return id === 0
        ? `${word.charAt(0).toLocaleUpperCase()}${word.slice(1)}`
        : word.toLowerCase()
    })
    .join(' ')
}
