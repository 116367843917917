import { ApplicableFilter, FilterItem } from '../../services/types/services'

export const applySelectedFilters = (
  filters: FilterItem[],
  selectedFilters: string[],
) => {
  return filters.reduce((acc: FilterItem[], filter: FilterItem) => {
    if (filter.__typename === 'FilterCategory') {
      const updatedChildren = applySelectedFilters(
        filter.children,
        selectedFilters,
      )
      acc.push({ ...filter, children: updatedChildren })
    } else if (
      ['Filter', 'FilterToggle', 'FilterInvertedToggle'].includes(
        filter.__typename,
      )
    ) {
      acc.push({
        ...filter,
        isSelected: selectedFilters.includes((filter as ApplicableFilter).id),
      } as ApplicableFilter)
    } else {
      acc.push(filter)
    }
    return acc
  }, [])
}
