import { useCallback } from 'react'
import { setUIHCookie as setUIHCookieFunction } from '@moonpig/web-shared-utils'
import { useSearchStore } from '../store/SearchStore'

export const useSetUIHCookie = () => {
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const preAppliedFilters = useSearchStore(store => store.preAppliedFilters)

  const setUIHCookie = useCallback(
    ({
      product,
      facets,
    }: {
      product: {
        id: string
        category: { id: string | number }
        isSponsored?: boolean
        internallyPromoted?: boolean
      }
      facets?: {
        group: string
        facetKey: string
      }[]
    }) =>
      setUIHCookieFunction({
        product,
        facets: facets || [
          ...selectedFilters.map(f => ({ facetKey: f.id, group: f.parent })),
          ...preAppliedFilters.map(f => ({
            facetKey: f.key,
            group: f.group,
          })),
        ],
      }),
    [selectedFilters, preAppliedFilters],
  )

  return { setUIHCookie }
}
