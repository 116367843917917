import { useEffect, useRef } from 'react'

export const useTrapFocusWithin = () => {
  const trappingAreaRef = useRef<HTMLDivElement>(null)

  const trapFocus = (element: HTMLElement) => {
    const focusableElements = element.querySelectorAll<HTMLElement>(
      'a[href], button, input[type="checkbox"], input[type="radio"], [tabindex]:not([tabindex="-1"])',
    )
    const firstElement = focusableElements[0]
    const lastElement = focusableElements[focusableElements.length - 1]

    const handleTabKey = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault()
            lastElement.focus()
          }
        } else if (document.activeElement === lastElement) {
          e.preventDefault()
          firstElement.focus()
        }
      }
    }

    element.addEventListener('keydown', handleTabKey)

    return () => {
      element.removeEventListener('keydown', handleTabKey)
    }
  }

  useEffect(() => {
    const trappingArea = trappingAreaRef?.current
    if (trappingArea) {
      const removeTrapFocus = trapFocus(trappingArea)

      return () => {
        removeTrapFocus()
      }
    }
  }, [])

  return trappingAreaRef
}
