import { gql } from '@moonpig/web-core-graphql'

const PRODUCT_FIELDS_FRAGMENT = gql`
  fragment RecommendationsProductVariant on ProductVariant {
    key
    title
    subtitle
    inStock
    sku
    minimumQuantity
    price {
      centAmount
      currencyCode
      fractionDigits
    }
    fullPrice {
      centAmount
      currencyCode
      fractionDigits
    }
    discountedPercentage
    bundles {
      description
      price {
        currencyCode
        centAmount
        fractionDigits
      }
      discountPercentage
      size
    }
    dimensions {
      description
    }
    capabilities {
      video
    }
  }

  fragment RecommendationsProduct on Product {
    id
    title
    slug
    dependencies
    customisable
    isLandscape
    category {
      id
      slug
      name
      department
    }
    rating {
      count
      score
    }
    masterVariant {
      ...RecommendationsProductVariant
      productImages {
        mainImage {
          medium {
            jpgUrl
          }
        }
        images {
          thumb {
            jpgUrl
          }
          small {
            jpgUrl
          }
          medium {
            jpgUrl
          }
          large {
            jpgUrl
          }
          extraLarge {
            jpgUrl
          }
          original {
            jpgUrl
          }
        }
      }
      images {
        url
      }
      masterImage {
        url
      }
    }
    variants {
      ...RecommendationsProductVariant
    }
    hasAugmentedReality
    productPills {
      displayLabel
      displayVariant
    }
    primaryProductPill {
      displayLabel
      displayVariant
    }
    publishDate
    isSponsored
    clickRankDocumentCount
  }
`

export const RecommendationsBySearchCriteriaGQL = gql`
  query RecommendationsBySearchCriteriaCrossSell(
    $searchTerm: String!
    $facetKeys: [String!]!
  ) {
    recommendationsBySearchCriteria(
      searchTerm: $searchTerm
      facetKeys: $facetKeys
    ) {
      ...RecommendationsProduct
    }
  }
  ${PRODUCT_FIELDS_FRAGMENT}
`
