import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { spacingPx } from '@moonpig/launchpad-theme'
import React, { FC, ReactNode } from 'react'
import { Flex, Heading, IconButton } from '@moonpig/launchpad-components'
import { IconSystemCross } from '@moonpig/launchpad-assets'

export type HeaderElements = ReactNode | 'close-button'

const StyledHeader = styled.div`
  border-top-left-radius: ${spacingPx(8)};
  border-top-right-radius: ${spacingPx(8)};
  ${s({
    pt: 4,
    bgcolor: 'colorBackground01',
    borderBottom: 1,
    borderColor: 'colorBorder03',
  })}
`

const StyledIconButton = styled(IconButton)<{ position: 'left' | 'right' }>`
  ${({ position }) =>
    s({
      marginRight: position === 'right' ? -6 : 0,
      marginLeft: position === 'left' ? -6 : 0,
    })}
`

type Props = {
  title?: string
  handleClosing: () => void
  headerElements: { left?: HeaderElements; right?: HeaderElements }
  headerRef: React.RefObject<HTMLDivElement>
}

export const DrawerHeader: FC<Props> = ({
  headerRef,
  title,
  headerElements,
  handleClosing,
}) => {
  const { left, right } = headerElements

  const renderElement = (
    element: HeaderElements,
    position: 'left' | 'right',
  ) => {
    if (element === 'close-button') {
      return (
        <StyledIconButton
          icon={IconSystemCross}
          label="Close"
          onClick={handleClosing}
          autoFocus
          position={position}
        />
      )
    }
    return element
  }

  return (
    <StyledHeader ref={headerRef}>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap={16}
        paddingX={6}
      >
        {left && renderElement(left, 'left')}
        {title && (
          <Heading level="h4" margin={0}>
            {title}
          </Heading>
        )}
        {right && renderElement(right, 'right')}
      </Flex>
    </StyledHeader>
  )
}
