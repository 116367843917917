import { GroupedFilters, SearchFilter } from '../types/services'

export const transformFiltersToGroupedFilters = (
  filters: SearchFilter[],
): GroupedFilters[] => {
  const groupedFilters: GroupedFilters[] = []
  filters.forEach(x => {
    const group = groupedFilters.find(y => y.group === x.group)

    return group
      ? group.keys.push(x.key)
      : groupedFilters.push({
          group: x.group,
          keys: [x.key],
          userApplied: x.userApplied,
        })
  })
  return groupedFilters
}
