import {
  SortOrder,
  Platform as GraphQLPlatform,
} from '@moonpig/web-explore-types-graphql'
import { type Platform } from '@moonpig/web-core-types'
import { ServiceOptions } from '../types/serviceOptions'
import {
  DYNAMIC_PAGE_TYPE,
  GALLERY_PAGE_TYPE,
  SEARCH_PAGE_TYPE,
} from '../../constants'

export type LoadOptionsProps = {
  pageType: string
  query: { [key: string]: string | string[] | undefined }
  platform: Platform
  department: string
}

export const loadOptions: (props: LoadOptionsProps) => ServiceOptions = ({
  pageType,
  query,
  platform,
  department,
}) => {
  const payPerClickFiltering = (query['only-ppc-results'] as string) === 'true'

  const shouldReturnSuggestions =
    pageType === SEARCH_PAGE_TYPE || pageType === DYNAMIC_PAGE_TYPE

  const shouldSearchOnAllParentDepartments =
    pageType === SEARCH_PAGE_TYPE || pageType === DYNAMIC_PAGE_TYPE

  const shouldReturnRecommendations =
    pageType === GALLERY_PAGE_TYPE && department === 'ALL_CARDS'

  return {
    payPerClickFiltering,
    platform: platform.isMobile
      ? GraphQLPlatform.MOBILE_WEB
      : GraphQLPlatform.WEB,
    defaultSortOrder: SortOrder.POPULARITY,
    shouldReturnSuggestions,
    shouldSearchOnAllParentDepartments,
    shouldReturnRecommendations,
  }
}
