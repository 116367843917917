import { FilterCategory, FilterItem } from '../../services/types/services'

export const updateCategories = (
  filters: FilterItem[],
  categoryIds: string[],
): FilterCategory[] =>
  filters.reduce((acc, filter) => {
    if (
      filter.__typename === 'FilterCategory' &&
      categoryIds.includes(filter.id)
    ) {
      return [...acc, filter, ...updateCategories(filter.children, categoryIds)]
    }
    return acc
  }, [] as FilterCategory[])
