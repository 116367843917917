import { SortOrder } from '@moonpig/web-explore-types-graphql'
import { getAllSelectedFilters } from './getAllSelectedFilters'
import { ApplicableFilter, FilterCategory } from '../../services/types/services'
import { isPersistedFilter } from '../../store/helpers/persistFilterSelection'

export const shouldShowClearButton = ({
  category,
  selectedFilters,
  sortValue,
  defaultSortValue,
}: {
  category?: FilterCategory
  selectedFilters?: ApplicableFilter[]
  sortValue?: SortOrder
  defaultSortValue?: SortOrder
}) => {
  if (sortValue !== defaultSortValue) {
    return true
  }

  if (!selectedFilters || selectedFilters.length === 0) {
    return false
  }

  const nonPersistedSelectedFilters = selectedFilters.filter(
    f => !isPersistedFilter(f),
  )

  return category
    ? getAllSelectedFilters(category.children).length > 0
    : nonPersistedSelectedFilters.length > 0
}
