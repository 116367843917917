import React, { FC, useEffect, useReducer, useMemo } from 'react'
import { getBrowserCookies, setBrowserCookie } from '@moonpig/web-core-cookies'
import { useStoreId } from '@moonpig/web-core-stores'
import { addToRecentlyViewed, initialise } from './actions'
import { reducer } from './Reducer'
import { dispatcherContext, InitialState, stateContext } from './Context'
import {
  deserialiseRecentlyViewed,
  serialiseRecentlyViewed,
} from './serialisation'
import type { Dispatcher } from './types'

const COOKIE_EXPIRATION = 259200

export const RecentlyViewedProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const storeId = useStoreId()
  const [state, dispatch] = useReducer(reducer, InitialState)
  const cookieName = `mnpg_recentlyviewed_${storeId}`
  const oldCookieName = 'recentlyViewedCompressed'

  const actions = useMemo<Dispatcher>(
    () => ({
      addToRecentlyViewed: params => {
        dispatch(addToRecentlyViewed({ ...params, region: storeId }))
      },
    }),
    [storeId],
  )

  useEffect(() => {
    const recentlyviewed = getBrowserCookies()[cookieName]

    let recentlyViewedProducts = []

    if (!recentlyviewed) {
      const recentlyViewedOld = getBrowserCookies()[oldCookieName]
      recentlyViewedProducts = deserialiseRecentlyViewed(recentlyViewedOld)
      setBrowserCookie(
        cookieName,
        serialiseRecentlyViewed(recentlyViewedProducts),
        {
          path: '/',
          maxAge: COOKIE_EXPIRATION,
        },
      )
    } else {
      recentlyViewedProducts = deserialiseRecentlyViewed(recentlyviewed)
    }

    dispatch(initialise({ recentlyViewedProducts }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setBrowserCookie(
      cookieName,
      serialiseRecentlyViewed(state.recentlyViewedProducts),
      {
        path: '/',
        maxAge: COOKIE_EXPIRATION,
      },
    )
  }, [state, cookieName])

  return (
    <dispatcherContext.Provider value={actions}>
      <stateContext.Provider value={state}>{children}</stateContext.Provider>
    </dispatcherContext.Provider>
  )
}
