import { Flex, TertiaryButton, Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemSort } from '@moonpig/launchpad-assets'

const StyledSortButton = styled(TertiaryButton)`
  display: flex;
  align-items: end;
  text-decoration: none;
  ${s({
    pt: 0,
  })}
`

const StyledSortButtonLabel = styled(Text)`
  ${s({
    color: 'colorTextBody',
    fontWeight: 'bold',
  })}
`

const StyledSortButtonSelectionLabel = styled(Text)`
  max-width: 120px;
  text-decoration: underline;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${s({
    fontWeight: 'bold',
  })}
`

type Props = {
  onClick: () => void
  sortByLabel: string
  selectedOptionLabel: string
}

export const SortButton: FC<Props> = ({
  onClick,
  sortByLabel,
  selectedOptionLabel,
}) => {
  return (
    <StyledSortButton
      trailingIcon={<IconSystemSort aria-hidden width="24px" />}
      onClick={onClick}
    >
      <Flex flexDirection="column" alignItems="start">
        <StyledSortButtonLabel>{sortByLabel}</StyledSortButtonLabel>
        <StyledSortButtonSelectionLabel>
          {selectedOptionLabel}
        </StyledSortButtonSelectionLabel>
      </Flex>
    </StyledSortButton>
  )
}
