import {
  DepartmentsEnum,
  ProductPillType,
} from '@moonpig/web-explore-types-graphql'
import { AppContext } from '../types'
import {
  RecommendationsRequest,
  RecommendationsRequestContext,
  RecommendationsResponse,
} from '../types/services'
import { runGraphQLQuery } from '../helpers/runGraphQLQuery'

import { RecommendationsBySearchCriteriaGQL } from './query'
import { mapProduct } from '../helpers/mapper'
import {
  RecommendationsBySearchCriteriaCrossSellQuery,
  RecommendationsBySearchCriteriaCrossSellQueryVariables,
} from './__generated__/query'

export type SearchPrice = {
  centAmount: number
  currencyCode: string
  fractionDigits: number
}

export type SearchBundle = {
  description: string
  price: SearchPrice
  discountPercentage: number
  size: number
}

export type SearchImage = {
  url: string
}

export type SearchVariant = {
  key: string
  title: string
  subtitle: string | null
  inStock: boolean
  sku: string | null
  minimumQuantity: number
  price: SearchPrice
  fullPrice: SearchPrice
  discountedPercentage?: number | null
  bundles: SearchBundle[]
  dimensions?: { description: string }
  capabilities?: { video: boolean }
}

export type SearchMasterVariant = {
  key: string
  title: string
  subtitle: string | null
  inStock: boolean
  sku: string | null
  minimumQuantity: number
  price: SearchPrice
  fullPrice: SearchPrice
  discountedPercentage?: number | null
  bundles: SearchBundle[]
  dimensions?: { description: string }
  capabilities?: { video: boolean }
  images: SearchImage[]
  masterImage: SearchImage
}

export type SearchRating = {
  count: number
  score: number
}

export type SearchCategory = {
  id: number
  slug: string
  name: string
  department: DepartmentsEnum
}

export type ProductPill = {
  displayLabel: string
  displayVariant: ProductPillType
}

export type SearchProduct = {
  id: string
  title: string
  slug: string
  customisable: boolean
  isLandscape: boolean
  category: SearchCategory
  rating?: SearchRating
  masterVariant: SearchMasterVariant
  variants: SearchVariant[]
  hasAugmentedReality: boolean
  publishDate?: string
  productPills: ProductPill[]
  primaryProductPill?: ProductPill
  isSponsored: boolean
  clickRankDocumentCount: number
}

const loadRecommendations: (
  context: AppContext,
  searchRequest: RecommendationsRequestContext,
) => Promise<RecommendationsResponse> = async (
  { client, options },
  { searchTerm, facetKeys },
) => {
  const { shouldReturnRecommendations } = options

  if (!shouldReturnRecommendations) {
    return { recommendationsBySearchCriteria: [] }
  }

  const result = await runGraphQLQuery<
    RecommendationsBySearchCriteriaCrossSellQuery,
    RecommendationsBySearchCriteriaCrossSellQueryVariables,
    RecommendationsResponse
  >({
    client,
    query: RecommendationsBySearchCriteriaGQL,
    variables: {
      searchTerm,
      facetKeys,
    },
    extractResult: data => {
      const products = data.recommendationsBySearchCriteria
        .filter(x => x.masterVariant && x.masterVariant.inStock)
        .map(product => mapProduct(product))

      return {
        recommendationsBySearchCriteria: products,
      }
    },
  })
  return {
    recommendationsBySearchCriteria: result.recommendationsBySearchCriteria,
  }
}

export const createRecommendations = (
  context: AppContext,
): RecommendationsRequest => {
  return async request => {
    return loadRecommendations(context, request)
  }
}
