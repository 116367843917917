// Lots of things here are nullable, when they shouldn't be
// To be fixed at the graph layer:
import { Region } from '@moonpig/web-core-types'
import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'

export enum LoadMoreType {
  SCROLL,
  CLICK,
  SCROLL_PREV,
  CLICK_PREV,
}

export type Category = {
  id: number
  slug: string
  name: string
}

export type Rating = {
  count?: number
  score: number
} | null

export type Image = {
  url: string
}

export type Price = {
  currencyCode: GraphQL$Currency
  centAmount: GraphQL$Long
  fractionDigits: number
}

export type FormattedPrice = {
  displayPrice: string
  description: string
  currencyCode: string
  currencySymbol: string
}

export type Variant = {
  title: string
  price: Price
  key: string
  inStock: boolean
  minimumQuantity: number
  bundles: ProductVariantBundle[]
  sku: string | null
  capabilities?: Capabilities | null
}

export type Capabilities = {
  video: boolean
}

export type ProductVariantBundle = {
  description: string
  price: Price
  discountPercentage: number
  size: number
}

export type FormattedVariant = {
  label: string
  price: FormattedPrice
  value: string
  selected: boolean
  inStock: boolean
  minimumQuantity: number
  sku: string
  bundles: ProductVariantBundle[]
}

export type FormattedVariantCategory = {
  label: string
  variants: FormattedVariant[]
  price: FormattedPrice
}

export type SearchDetails = {
  region: Region
  url: string
  department: DepartmentsEnum[]
  searchTerm: string
  promotionId?: string
  correctedSearchTerm?: string
}

export type Department = DepartmentsEnum

export type LocationLocalisationKey =
  | 'find.personalised_cards_cristmas_all'
  | 'find.personalised_cards_fathers_day_all'
  | 'find.personalised_cards_mothers_day_all'
  | 'find.personalised_cards_valentines_day_all'

export type SelectedSuggestion = {
  group: string
  facetKey: string
  label: string
  userApplied?: boolean
}

export type FiltersPageType = 'Gallery' | 'Search'

export enum PageLoadState {
  Loaded,
  LoadingNext,
  LoadingPrev,
  Error,
}
