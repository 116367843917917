import { useExperiment } from '@moonpig/web-core-experiments'
import { useGetBreakpoint } from '@moonpig/web-shared-utils'
import { getTilesPerRow } from './getTilesPerRow'

export const useNumberOfTilesPerRow = (useExperiments = true) => {
  const breakpoint = useGetBreakpoint()
  const showCardProductTileVariant =
    useExperiment('search-new-gallery-ux-v2', 'Original')?.toLowerCase() ===
    'enabled'

  const currentTilesPerRow = getTilesPerRow(
    breakpoint,
    useExperiments && showCardProductTileVariant,
  )

  return currentTilesPerRow
}
