import { OperationVariables } from '@apollo/client'
import { retry } from '@lifeomic/attempt'
import { GraphQLContext } from '@moonpig/web-core-graphql'
import { logger } from '@moonpig/web-core-monitoring'
import { DocumentNode } from 'graphql'

export const runGraphQLQuery = async <
  TData,
  TVariables extends OperationVariables,
  TResult = TData,
>({
  client,
  query,
  variables,
  extractResult,
  retryDelay = 100,
  retryAttempts = 3,
}: {
  client: {
    query: GraphQLContext['query']
  }
  query: DocumentNode
  variables: TVariables
  extractResult: (data: TData) => TResult
  retryDelay?: number
  retryAttempts?: number
}): Promise<TResult> => {
  const operation = query.definitions[0]

  /* istanbul ignore next: unreachable */
  if (operation.kind !== 'OperationDefinition') {
    throw new Error('Expected query or mutation OperationDefinition.')
  }

  return retry(
    async () => {
      const result = await client.query<TData, TVariables>({
        query,
        variables,
        errorPolicy: 'all',
      })

      if (result?.data) {
        return extractResult(result.data)
      }

      const errors =
        result?.errors ?? /* istanbul ignore next: unreachable */ []

      const message = errors.map(e => e.message).join(', ')

      throw new Error(message)
    },
    {
      maxAttempts: retryAttempts || 1,
      delay: retryDelay,
      factor: 2,
      handleError(err, { attemptNum }) {
        logger.info(`Retrying ${operation.name?.value}`, { attemptNum }, err)
      },
    },
  )
}
