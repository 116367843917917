import React from 'react'
import { Text } from '@moonpig/launchpad-components'

export const StyledLabel = (
  label: string,
  isSelected: boolean,
): JSX.Element => {
  const milestoneRegex = /^[0-9]+(?:ste|nd|rd|th|st|e)/
  const milestone = label?.match(milestoneRegex)
  const labelWithRemovedMilestone = label.replace(milestoneRegex, '')

  return (
    <Text fontWeight={isSelected ? 'bold' : 'normal'}>
      {milestone && <b>{milestone}</b>}
      {labelWithRemovedMilestone}
    </Text>
  )
}
