import { FilterCategory, ApplicableFilter } from '../../services/types/services'

const isWithinCategory = (
  filter: ApplicableFilter,
  category: FilterCategory,
): boolean => {
  const found = category.children.find(child => {
    if (child.__typename === 'Filter') {
      return filter.id === child.id
    }
    if (child.__typename === 'FilterCategory') {
      return isWithinCategory(filter, child as FilterCategory)
    }
    return false
  })

  return !!found
}

export const clearCategoryFilters = (
  category: FilterCategory,
  selectedFilters: ApplicableFilter[],
) => {
  return selectedFilters.filter(filter => !isWithinCategory(filter, category))
}
