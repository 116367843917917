export const setOffsetAndProductIdInUrl = (
  offset: number,
  productId?: string,
) => {
  const currentURL = new URL(window.location.href)
  if (offset) {
    currentURL.searchParams.set('offset', String(offset))
  } else {
    currentURL.searchParams.delete('offset')
  }

  if (productId) {
    currentURL.searchParams.set('productId', productId)
  } else {
    currentURL.searchParams.delete('productId')
  }
  window.history.replaceState(window.history.state, '', currentURL.toString())
}
