import {
  createGenericSelectContentEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import {
  StateProps,
  TrackingActionTypes,
  TrackingActionsEnum,
  TrackingPayload,
} from '../types'
import { getTrackingLabelForSortOrder } from '../../utils/getTrackingLabel'
import { FilterCategory } from '../../services/types/services'

export const trackEvent = ({
  action,
  oldValues,
  newValues,
  payload,
}: {
  action: TrackingActionTypes
  payload: TrackingPayload
  oldValues: StateProps
  newValues: StateProps
}) => {
  switch (action) {
    case TrackingActionsEnum.toggleFiltersMenu:
      trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: `${newValues.pageContext.type} action`,
          eventAction: newValues.filtersMenuOpen
            ? 'filters opened'
            : 'filters closed',
          eventLabel: newValues.pageContext.title,
        }),
      )
      break

    case TrackingActionsEnum.toggleFiltersCategory:
      trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: `${newValues.pageContext.type} action`,
          eventAction:
            newValues.selectedCategories.length >
            oldValues.selectedCategories.length
              ? 'expand'
              : 'collapse',
          eventLabel:
            newValues.selectedCategories.length >
            oldValues.selectedCategories.length
              ? newValues.selectedCategories[
                  newValues.selectedCategories.length - 1
                ].label
              : oldValues.selectedCategories[
                  oldValues.selectedCategories.length - 1
                ].label,
        }),
      )
      break

    case TrackingActionsEnum.clearFilters:
      trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: `${newValues.pageContext.type} action`,
          eventAction: 'clear filters',
          eventLabel: (payload[0] as { category: FilterCategory }).category
            ? `${
                (payload[0] as { category: FilterCategory }).category.label
              } category`
            : 'all',
        }),
      )
      break

    case TrackingActionsEnum.sortBy:
      trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: `${newValues.pageContext.type} action`,
          eventAction: 'sort by',
          eventLabel: getTrackingLabelForSortOrder(payload[0] as string),
        }),
      )
      break
  }
}
