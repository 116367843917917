import React, { useCallback } from 'react'
import { LoadMoreType } from '../../components/types'
import {
  ProductListingPageAction,
  ProductListingPageState,
} from '../productListingPageContext'

type GalleryLoadMoreHook = {
  dispatch: React.Dispatch<ProductListingPageAction>
  loadMoreCallback: (isPrevious?: boolean) => Promise<boolean>
  totalCount: number
  state: ProductListingPageState
}

export const useGalleryLoadMore = ({
  dispatch,
  loadMoreCallback,
}: GalleryLoadMoreHook) => {
  const onLoadMoreCallback = useCallback(
    async (loadMoreType: LoadMoreType) => {
      let result = false
      const isPrevious =
        loadMoreType === LoadMoreType.SCROLL_PREV ||
        loadMoreType === LoadMoreType.CLICK_PREV

      dispatch({
        type: 'BEGIN_LOAD_MORE',
        payload: { modified: true },
      })

      result = await loadMoreCallback(isPrevious)

      dispatch({
        type: 'END_LOAD_MORE',
        payload: {},
      })

      return result
    },
    [dispatch, loadMoreCallback],
  )

  return {
    onLoadMoreCallback,
  }
}
