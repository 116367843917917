import { createGenericSelectContentEvent } from '@moonpig/web-core-analytics'
import { Price } from '../components/types'
import { GetUpcomingOccasions_me_customer_recommendations_products as RecommendedProducts } from '../queries/types-graphql'

type Variant = {
  title: string
  price: Price
}

type MasterVariant = Variant & {
  images: { url: string }[]
  masterImage: { url: string }
  inStock: boolean
}

type Breadcrumb = {
  label: string
  url: string
}

export type ModalOpenGAEventDetailsProduct = {
  id: string
  title: string
  category: {
    name: string
  }
}

export type ModalVPVEventDetailsProduct = {
  id: string
  title: string
  customisable: boolean
  masterVariant: MasterVariant
  category: {
    name: string
    slug: string
  }
  slug: string
  isFavourite?: boolean
  productIndex?: number
}

export type ResultsLoadedGAEventDetailsProduct = ModalVPVEventDetailsProduct

export const loadMoreGAEventDetails = (productCount: number) => {
  return createGenericSelectContentEvent({
    eventCategory: 'gallery action',
    eventAction: 'load more',
    eventLabel: productCount.toString(),
  })
}

export const generateBreadcrumbsClickGAEvent = (
  breadcrumbs?: Array<Breadcrumb>,
) => {
  const getBreadcrumbHierarchy = () => {
    if (!breadcrumbs) return undefined
    const result: string[] = []
    breadcrumbs.forEach((breadcrumb: Breadcrumb) =>
      result.push(breadcrumb.label),
    )
    return result.join(' > ')
  }

  const breadcrumbHierarchy = getBreadcrumbHierarchy()
  return {
    event: 'select_content',
    content_type: `breadcrumb | ${breadcrumbHierarchy}`,
    event_data: {
      category: 'site breadcrumb selection',
      action: 'global navigation link click',
      label: breadcrumbHierarchy,
      nonInteraction: true,
      value: undefined,
    },
  }
}

export type RecentlyViewedGAEventProduct = {
  id: string
  title: string
  category: {
    name: string
  }
  masterVariant: {
    price: {
      centAmount: number
    }
  }
}

export type ProductsInRecentlyViewed = {
  products: RecentlyViewedGAEventProduct[]
  pageType: string
}

export type NumberOfProductsInRecentlyViewed = {
  productIndex: number
  productCount: number
  pageTitle: string
}

export type RecommendedForYouCarouselImpressions = {
  type: string
  products: RecommendedProducts[]
}

export const ExpandOrMinimiseRecentlyViewedGAEvent = (
  expandOrMinimise: string,
) => {
  return createGenericSelectContentEvent({
    eventCategory: 'product action',
    eventAction: 'list click',
    eventLabel: `Recently Viewed | footer carousel | ${expandOrMinimise}`,
  })
}

export const generateDepartmentHeadersClickGAEvent = (
  searchTerm: string,
  departmentName: string,
  departmentCount: number,
) => {
  return createGenericSelectContentEvent({
    eventCategory: 'search',
    eventAction: 'global header click',
    eventLabel: `${searchTerm} in ${departmentName} | ${departmentCount}`,
  })
}
