import { keyframes, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { spacingPx } from '@moonpig/launchpad-theme'

const slideInHorizontalRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0.5;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
`

const slideInHorizontalLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0.5;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
`

const slideOutHorizontalRight = keyframes`
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0.5;
  }
`

const slideOutHorizontalLeft = keyframes`
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0.5;
  }
`

const slideInVerticalBottom = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
`

const slideInVerticalTop = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
`

const slideOutVerticalBottom = keyframes`
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0.5;
  }
`

const slideOutVerticalTop = keyframes`
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0.5;
  }
`

const animationBezier = 'cubic-bezier(0.56, 0.1, 0.34, 0.91)'
const animationDuration = '450ms'

export const DrawerContent = styled.div<{ offset: number }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  border-top-left-radius: ${spacingPx(8)};
  border-top-right-radius: ${spacingPx(8)};

  ${s({
    bgcolor: 'colorBackground01',
  })}

  &.top {
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    max-height: ${({ offset }) => `calc(100vh - ${offset}px)`};

    &.open {
      animation: ${animationDuration} ${animationBezier} ${slideInVerticalTop}
        forwards;
      @media (prefers-reduced-motion) {
        animation: none;
        transform: translateY(0%);
      }
    }

    &.close {
      animation: ${animationDuration} ${animationBezier} ${slideOutVerticalTop}
        forwards;
      @media (prefers-reduced-motion) {
        animation: none;
        transform: translateY(-100%);
      }
    }
  }

  &.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    max-height: ${({ offset }) => `calc(100vh - ${offset}px)`};

    &.open {
      animation: ${animationDuration} ${animationBezier}
        ${slideInVerticalBottom} forwards;
      @media (prefers-reduced-motion) {
        animation: none;
        transform: translateY(0%);
      }
    }

    &.close {
      animation: ${animationDuration} ${animationBezier}
        ${slideOutVerticalBottom} forwards;
      @media (prefers-reduced-motion) {
        animation: none;
        transform: translateY(100%);
      }
    }
  }

  &.left {
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(-100%);
    max-width: ${({ offset }) => `calc(100vw - ${offset}px)`};

    &.open {
      animation: ${animationDuration} ${animationBezier}
        ${slideInHorizontalLeft} forwards;
      @media (prefers-reduced-motion) {
        animation: none;
        transform: translateX(0%);
      }
    }

    &.close {
      animation: ${animationDuration} ${animationBezier}
        ${slideOutHorizontalLeft} forwards;
      @media (prefers-reduced-motion) {
        animation: none;
        transform: translateX(-100%);
      }
    }
  }

  &.right {
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    max-width: ${({ offset }) => `calc(100vw - ${offset}px)`};
    min-width: 100px;

    &.open {
      animation: ${animationDuration} ${animationBezier}
        ${slideInHorizontalRight} forwards;
      @media (prefers-reduced-motion) {
        animation: none;
        transform: translateX(0%);
      }
    }

    &.close {
      animation: ${animationDuration} ${animationBezier}
        ${slideOutHorizontalRight} forwards;
      @media (prefers-reduced-motion) {
        animation: none;
        transform: translateX(100%);
      }
    }
  }
`
