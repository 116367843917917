import React, { FC } from 'react'
import { FilterToggle } from '..'
import { useSearchStore } from '../../../../store/SearchStore'
import { FilterSource } from '../../../../types'
import { InvertedFilterToggle } from '../../../../services/types/services'

type Props = {
  /** Filter object */
  filter: InvertedFilterToggle
}

export const RudeToggle: FC<Props> = ({ filter }) => {
  const toggleFilter = useSearchStore(store => store.toggleFilter)

  const onChange = () => {
    toggleFilter({
      filter,
      select: !filter.isSelected,
      source: FilterSource.Toggle,
    })
  }

  return <FilterToggle filter={filter} onChange={onChange} />
}
