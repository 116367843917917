import { createContext, useContext } from 'react'
import { Dispatcher } from './types'

export const dispatcherContext = createContext<Dispatcher | null>(null)

export const useDrawer = () => {
  const context = useContext(dispatcherContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('DrawerProvider is missing.')
  }
  return context
}
