import { SponsoredProductInput } from '@moonpig/web-explore-types-graphql'
import {
  GraphQLSearchMasterVariant,
  GraphQLSearchProduct,
  GraphQLSearchProductRating,
  GraphQLSearchVariant,
} from '../types/graphql-search'
import {
  SearchMasterVariant,
  SearchProduct,
  SearchRating,
  SearchVariant,
} from '../types/services'

export const mapProduct: (
  product: GraphQLSearchProduct,
  sponsoredProducts?: SponsoredProductInput[],
) => Omit<SearchProduct, 'productIndex'> = (product, sponsoredProducts) => {
  const isSponsoredProduct = sponsoredProducts?.find(
    (ele: SponsoredProductInput) => ele.productKey === product.id,
  )

  return {
    ...product,
    internallyPromoted: !!isSponsoredProduct?.internallyPromoted,
    dependencies: removeNulls(product.dependencies),
    rating: mapRating(product.rating),
    variants: product.variants.map(v => mapVariant(v)),
    masterVariant: mapMasterVariant(product.masterVariant),
    publishDate: product.publishDate || undefined,
    primaryProductPill: product.primaryProductPill || undefined,
  }
}

const mapRating: (
  rating: GraphQLSearchProductRating | null,
) => SearchRating | undefined = rating => {
  return rating || undefined
}

const mapVariant: (
  variant: GraphQLSearchVariant,
) => SearchVariant = variant => {
  return {
    ...variant,
    dimensions: variant.dimensions || undefined,
    capabilities: variant.capabilities || undefined,
  }
}

const mapMasterVariant: (
  variant: GraphQLSearchMasterVariant,
) => SearchMasterVariant = variant => {
  return {
    ...variant,
    masterImage: {
      ...variant.masterImage,
      url: variant.productImages.mainImage.medium.jpgUrl,
    },
    dimensions: variant.dimensions || undefined,
    capabilities: variant.capabilities || undefined,
  }
}

function removeNulls<T>(array: (T | null)[]): T[] {
  return array.filter(value => value !== null) as T[]
}
