import { DepartmentsEnum, SortOrder } from '@moonpig/web-explore-types-graphql'
import { SearchFilter } from '../services'
import { SelectedSuggestion } from '../components/types'
import { FilteringEvent, FilterSource } from '../types'

type SearchGAEventProps = {
  correctedSearchTerm: string
  department: DepartmentsEnum[]
  filters: SearchFilter[]
  filteringEvent?: FilteringEvent
  numberOfResults: number
  searchTerm: string
  sort: SortOrder
  selectedSuggestion?: SelectedSuggestion
}

const maxCharLimit = 500

// GA4 event: 'search'
// https://github.com/Moonpig/moonpig-data-tracking/blob/main/events/specs/search.md
export const createSearchGAEvent = ({
  correctedSearchTerm,
  department,
  filters,
  filteringEvent,
  numberOfResults,
  searchTerm,
  sort,
  selectedSuggestion,
}: SearchGAEventProps) => {
  const filterSource =
    filteringEvent &&
    filteringEvent.source !== FilterSource.Gallery &&
    filteringEvent.source
  const source = filterSource || 'keyword'
  const selectedFilter = filteringEvent?.filter

  const inputType: string = selectedSuggestion?.facetKey
    ? 'facet suggestion'
    : source

  const getLabelText = () => {
    if (correctedSearchTerm) {
      return `${searchTerm} | ${correctedSearchTerm}`.toLowerCase()
    }
    if (searchTerm) {
      return searchTerm.toLowerCase()
    }
    if (selectedSuggestion?.facetKey) {
      return selectedSuggestion?.facetKey.toLowerCase()
    }
    return undefined
  }

  return {
    event: 'search',
    event_data: {
      category: 'search',
      action: 'run search',
      label: getLabelText(),
      non_interaction: true,
      value: undefined,
    },

    results_data: {
      input_type: inputType,
      results_list:
        inputType === 'facet suggestion'
          ? selectedSuggestion?.facetKey?.toLowerCase()
          : searchTerm.toLowerCase(),
      corrected_search_term: correctedSearchTerm
        ? correctedSearchTerm.toLowerCase()
        : undefined,
      product_category:
        (department.length && department[0].toLowerCase()) ||
        /* istanbul ignore next */ 'n/a',
      number_of_results: numberOfResults,
      sort_by: sort.toLowerCase(),
    },

    filter_data: {
      filter_name:
        filters.length > 0
          ? filters
              .map(f => f.key)
              .join('|')
              .toLowerCase()
              .substring(0, maxCharLimit)
          : undefined, // maxCharLimit hard limit
      last_filter_interaction: filteringEvent?.action
        ? `${filteringEvent.action} | ${selectedFilter?.parent} | ${selectedFilter?.id}`.toLowerCase()
        : undefined,
      number_of_active_filters: filters.length,
    },

    // Web only //
    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}
