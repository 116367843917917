import { styled, keyframes } from '@moonpig/launchpad-utils'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const DrawerOverlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  &.open {
    animation: 450ms cubic-bezier(0.56, 0.1, 0.34, 0.91) ${fadeIn} forwards;
    @media (prefers-reduced-motion) {
      animation: none;
      opacity: 1;
    }
  }

  &.close {
    animation: 450ms cubic-bezier(0.56, 0.1, 0.34, 0.91) ${fadeOut} forwards;
    @media (prefers-reduced-motion) {
      animation: none;
      opacity: 0;
    }
  }
`
