import React from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useInView } from 'react-intersection-observer'
import { Region } from '@moonpig/web-core-types'
import { ProductTile } from './ProductTile'
import { ProductTileProduct } from './ProductTileProduct'
import { AddedToBasketEvent, ProductClickedEvent } from '../../types'

type Props = {
  isCardProduct: boolean
  product: ProductTileProduct
  productIndex: number
  totalNumberOfProducts: number
  trackingData: {
    region: Region
    pageType: string
    metaTitle?: string
  }
  onClick: (clickedEvent: ProductClickedEvent) => void
  onFirstClick?: (clickedEvent: ProductClickedEvent) => void
  onAddToBasket: (addedEvent: AddedToBasketEvent) => void
  onEndApproaching?: () => void
  onTopApproaching?: () => void
  onOffsetBorderApproaching?: (productIndex: number) => void
  isInternalUser?: boolean
  isLastItem: boolean
  isFirstItem?: boolean
  isOffsetBorderItem?: boolean
  isFavourited: boolean
  handleFavourite: (
    product: ProductTileProduct,
    isFavouriteSelected: boolean,
    index: number,
  ) => Promise<{ removeWithConfirmation: boolean }>
  isGallery?: boolean | undefined
  groupCardProject?: string
  showCardProductTileVariant?: boolean
}

export const TrackedProductTile: React.FC<Props> = ({
  isCardProduct,
  product,
  productIndex,
  totalNumberOfProducts,
  trackingData,
  onClick,
  onFirstClick,
  onAddToBasket,
  isInternalUser = false,
  isLastItem,
  isFirstItem,
  isOffsetBorderItem,
  onEndApproaching,
  onTopApproaching,
  onOffsetBorderApproaching,
  isFavourited,
  handleFavourite,
  isGallery,
  groupCardProject,
  showCardProductTileVariant = false,
}) => {
  const skipTracking = [isLastItem, isFirstItem, isOffsetBorderItem].every(
    item => !item,
  )

  const [tileRef] = useInView({
    threshold: 0.5,
    skip: skipTracking,
    onChange: inView => {
      if (inView) {
        if (isFirstItem && onTopApproaching) {
          onTopApproaching()
        }
        if (isLastItem && onEndApproaching) {
          onEndApproaching()
        }
        if (isOffsetBorderItem && onOffsetBorderApproaching) {
          onOffsetBorderApproaching(productIndex)
        }
      }
    },
  })

  const trackingDataProps = {
    ...trackingData,
    tile: {
      totalNumberOfProducts,
      productIndex,
    },
  }
  /* istanbul ignore next */
  const cardProductPadding =
    showCardProductTileVariant && isCardProduct && isGallery
      ? 4
      : { xs: 4, lg: 5, xxl: 10 }

  return (
    <Box
      ref={tileRef}
      height="100%"
      p={isCardProduct && !showCardProductTileVariant ? cardProductPadding : 0}
      data-testid="web-find-product-tile-wrapper"
    >
      {isLastItem && <div data-testid="last-item-marker" />}
      {isOffsetBorderItem && <div data-testid="offset-border-item-marker" />}
      {isFirstItem && <div data-testid="first-item-marker" />}
      <ProductTile
        product={product}
        trackingData={trackingDataProps}
        onFirstClick={onFirstClick}
        onClick={onClick}
        onAddToBasket={onAddToBasket}
        data-testid="web-find-product-with-modal"
        isInternalUser={isInternalUser}
        handleFavourite={handleFavourite}
        isFavourited={isFavourited}
        isGallery={isGallery}
        hideStickyCta
        groupCardProject={groupCardProject}
        showCardProductTileVariant={showCardProductTileVariant}
      />
    </Box>
  )
}

TrackedProductTile.displayName = 'TrackedProductTileWithModal'
