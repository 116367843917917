import { Container } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { FiltersMenuButton } from '../FiltersCarousel/components/FiltersMenuButton'
import { useSearchStore } from '../../store/SearchStore'
import { DropDownMenu } from './DropDownMenu'
import { FilterCategory } from '../../services/types/services'
import { Carousel } from '../Carousel'

const StyledQuickFiltersBar = styled(Container)`
  ${s({
    width: '100%',
    boxShadow: '0px 4px 4px -4px rgba(0, 32, 77, 0.15)',
    bgcolor: 'colorBackground01',
    mb: 4,
    pb: 3,
    px: 0,
  })}
`

export const QuickFilters: FC = () => {
  const toggleFiltersMenu = useSearchStore(store => store.toggleFiltersMenu)
  const filtersMenuOpen = useSearchStore(store => store.filtersMenuOpen)
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const filters = useSearchStore(store => store.filters)
  const toggleFilter = useSearchStore(store => store.toggleFilter)
  const resultsLoading = useSearchStore(store => store.resultsLoading)
  const resultsNumber = useSearchStore(store => store.results)
  const toggleFiltersCategory = useSearchStore(
    store => store.toggleFiltersCategory,
  )

  return (
    <StyledQuickFiltersBar data-testid="dropdown-filters">
      <Carousel itemsGap={{ xs: 3 }} noSpacing>
        <FiltersMenuButton
          toggleMenu={toggleFiltersMenu}
          isMenuOpen={filtersMenuOpen}
          appliedFilters={selectedFilters}
        />

        {(
          filters.filter(
            f => f.__typename === 'FilterCategory' && f.isQuickFilter,
          ) as FilterCategory[]
        ).map(filterCategory => {
          return (
            <DropDownMenu
              filterCategory={filterCategory}
              selectedFilters={selectedFilters}
              key={`${filterCategory.id}-quick-filter`}
              onChange={() => toggleFiltersCategory(filterCategory)}
              toggleFilter={toggleFilter}
              count={resultsNumber.count}
              loading={resultsLoading}
            />
          )
        })}
      </Carousel>
    </StyledQuickFiltersBar>
  )
}
