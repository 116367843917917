import { Region } from '@moonpig/web-core-types'
import { ProductInfoProduct } from '../../types'
import type { RecentlyViewedProduct, AddToRecentlyViewed } from './types'

type InitialiseAction = {
  type: 'INITIALISE'
  recentlyViewedProducts: RecentlyViewedProduct[]
}

type AddToRecentlyViewedAction = {
  type: 'ADD_TO_RECENTLY_VIEWED'
  product: ProductInfoProduct
  region: Region
  index?: number
}

export const addToRecentlyViewed = (
  params: AddToRecentlyViewed & { region: Region },
): AddToRecentlyViewedAction => {
  return { type: 'ADD_TO_RECENTLY_VIEWED', ...params }
}

export const initialise = (params: {
  recentlyViewedProducts: RecentlyViewedProduct[]
}): InitialiseAction => {
  return {
    type: 'INITIALISE',
    ...params,
  }
}
