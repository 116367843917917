import { FilterItem, ApplicableFilter } from '../../services/types/services'

const findFilterById = (
  filters: FilterItem[],
  id: string,
): FilterItem | null => {
  return filters.reduce<FilterItem | null>((found, filter) => {
    if (found) return found
    if (
      (filter.__typename === 'Filter' ||
        filter.__typename === 'FilterToggle' ||
        filter.__typename === 'FilterInvertedToggle') &&
      filter.id === id
    ) {
      return { ...filter, isSelected: true }
    }
    if (filter.__typename === 'FilterCategory' && filter.children) {
      if (filter.id === id) {
        return { ...filter, isSelected: true }
      }
      return findFilterById(filter.children, id)
    }
    return null
  }, null)
}

type Props = {
  filterIds: string[]
  allFilters: FilterItem[]
}

export const getFiltersById = ({
  filterIds,
  allFilters,
}: Props): ApplicableFilter[] => {
  const uniqueFilterIds = Array.from(new Set(filterIds))
  const urlFilters = uniqueFilterIds
    .map(filterId => findFilterById(allFilters, filterId))
    .filter(Boolean)

  return urlFilters as ApplicableFilter[]
}
