import { SearchFilter } from '../../services/types'
import { FilterItem } from '../../services/types/services'

export const removePreAppliedFilters = (
  filters: FilterItem[],
  preAppliedFilters: SearchFilter[],
): FilterItem[] => {
  const nonUserAppliedFilterIds: string[] =
    groupPresetFiltersAndExtractIds(preAppliedFilters)

  return filters.reduce((acc: FilterItem[], filter: FilterItem) => {
    if (filter.__typename === 'FilterCategory') {
      const updatedChildren = removePreAppliedFilters(
        filter.children,
        preAppliedFilters,
      )
      acc.push({ ...filter, children: updatedChildren })
    } else if (
      filter.__typename === 'Filter' &&
      !nonUserAppliedFilterIds.includes(filter.id)
    ) {
      acc.push(filter)
    } else if (filter.__typename !== 'Filter') {
      acc.push(filter)
    }
    return acc
  }, [])
}

const groupPresetFiltersAndExtractIds = (
  preAppliedFilters: SearchFilter[],
): string[] => {
  const groupCounts: Record<string, { count: number; filter: SearchFilter }> =
    {}
  const resultSet = new Set<string>()

  preAppliedFilters.forEach(filter => {
    const group = filter.group

    if (!groupCounts[group]) {
      groupCounts[group] = { count: 0, filter }
    }

    groupCounts[group].count += 1

    if (groupCounts[group].count > 1) {
      const filterToRemove = groupCounts[group].filter
      resultSet.delete(filterToRemove.key)
    } else if (!filter.userApplied) {
      resultSet.add(filter.key)
    }
  })

  return Array.from(resultSet)
}
