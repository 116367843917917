import { SiteSpectCampaigns } from '@moonpig/web-core-ab-testing'

const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z0-9])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  )

export const getExperimentsFromCampaigns = (campaigns: SiteSpectCampaigns) => {
  const experiments =
    campaigns &&
    campaigns.reduce(
      (agg, { Name: campaignName, VariationGroup: { Name: variantName } }) => ({
        ...agg,
        [snakeToCamel(campaignName)]: variantName,
      }),
      {},
    )
  return experiments
}
