import { compressToBase64, decompressFromBase64 } from 'lz-string'
import { RecentlyViewedProduct } from './types'

export const serialiseRecentlyViewed = (
  products: RecentlyViewedProduct[],
): string => compressToBase64(JSON.stringify(products))

export const deserialiseRecentlyViewed = (
  compressed: string | undefined | null,
): RecentlyViewedProduct[] =>
  JSON.parse(
    decompressFromBase64(compressed ?? '') ?? '[]',
  ) as RecentlyViewedProduct[]
