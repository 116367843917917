import React, { FC } from 'react'
import {
  FormControlLabel,
  RadioButton,
  RadioGroup,
} from '@moonpig/launchpad-forms'
import { styled } from '@moonpig/launchpad-utils'

const StyledFormControlLabel = styled(FormControlLabel)`
  padding: 8px;
  height: 54px;
`

type Props = {
  options: { label: string; value: string }[]
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
}

export const SortOptions: FC<Props> = ({
  options,
  value,
  onChange,
  disabled,
}) => {
  return (
    <RadioGroup name="sort-by" legend="">
      {options?.map(sortByOption => (
        <StyledFormControlLabel
          label={sortByOption.label}
          id={sortByOption.value}
          key={sortByOption.value}
          disabled={disabled}
        >
          <RadioButton
            checked={value === sortByOption.value}
            onChange={onChange}
            value={sortByOption.value}
          />
        </StyledFormControlLabel>
      ))}
    </RadioGroup>
  )
}
