import { Region } from '@moonpig/web-core-types'
import { ProductInfoProduct } from '../../types'
import { RecentlyViewedProductEnriched } from './types'

export const mapRecentlyViewed = (params: {
  product: ProductInfoProduct
  region: Region
}): RecentlyViewedProductEnriched => {
  const { product, region } = params
  return {
    id: product.id,
    image: product.masterVariant.masterImage.url,
    title: product.title,
    url: `/${region}/${product.category.slug}/p/${product.slug}/${product.id}`,
    masterVariant: {
      title: product.masterVariant.title,
      price: {
        currencyCode: product.masterVariant.price.currencyCode,
        centAmount: product.masterVariant.price.centAmount,
      },
      inStock: product.masterVariant.inStock,
    },
    category: {
      name: product.category.name,
      department: product.category.department,
    },
  }
}
