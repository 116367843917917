import React, { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { Toggle as FiltersMenuToggle } from '../../FiltersMenu'
import { ApplicableFilter } from '../../../services/types/services'

type Props = {
  onInView?: (inView: boolean) => void
  toggleMenu: () => void
  isMenuOpen: boolean
  appliedFilters: ApplicableFilter[]
}

export const FiltersMenuButton: FC<Props> = ({
  onInView,
  toggleMenu,
  isMenuOpen,
  appliedFilters,
}) => {
  const [ref] = useInView({
    threshold: 0,
    triggerOnce: false,
    onChange: onInView,
  })

  return (
    <FiltersMenuToggle
      onClick={toggleMenu}
      isOpen={isMenuOpen}
      appliedFiltersCount={appliedFilters.length}
      ref={ref}
    />
  )
}
