import { PrimaryButton } from '@moonpig/launchpad-components'
import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  IconSystemChevronDown,
  IconSystemChevronUp,
} from '@moonpig/launchpad-assets'
import { useFindLocaleText } from '../../../text-localisation'

const StyledPrimaryButton = styled(PrimaryButton)<{ count: number }>`
  ${({ count }) =>
    s({
      py: 0,
      mr: 3,
      borderColor: count
        ? 'colorInteractionSelectedState'
        : 'colorInteractionIcon',
      bgcolor: count ? 'colorBackground03' : 'colorBackground01',
      border: 2,
      color: count ? 'colorInteractionSelectedState' : 'colorInteractionIcon',
    })}

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      ${s({
        borderColor: 'colorInteractionButton',
        bgcolor: 'colorBackground06',
        color: 'colorInteractionButton',
      })}
    }
  }
`

type Props = {
  /** The label for the button */
  label: string
  /** The callback function to call when the button is clicked */
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Whether the dropdown is open or not */
  isOpen: boolean
  /** The number of filters applied */
  count: number
} & { ref?: React.Ref<HTMLButtonElement> }

export const DropDownButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const localiseText = useFindLocaleText()

    const trailingIcon = props.isOpen ? (
      <IconSystemChevronUp />
    ) : (
      <IconSystemChevronDown />
    )

    const ariaLabel = localiseText(
      props.isOpen
        ? 'find.filters_menu_panel.close'
        : 'find.filters_menu_panel.open',
      props.label,
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      props.onClick(event)
    }

    return (
      <StyledPrimaryButton
        aria-label={ariaLabel}
        {...props}
        ref={ref}
        onClick={handleClick}
        trailingIcon={trailingIcon}
        count={props.count}
      >
        {props.label}
      </StyledPrimaryButton>
    )
  },
)
