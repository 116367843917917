import { TilesPerRow } from '../../types'

export const getTilesPerRow = (
  breakpoint: keyof TilesPerRow,
  showCardProductTileVariant: boolean,
) => {
  /* istanbul ignore next */
  const xxlProductsPerRow = showCardProductTileVariant ? 4 : 6
  /* istanbul ignore next */
  const xlProductsPerRow = showCardProductTileVariant ? 4 : 5
  /* istanbul ignore next */
  const tilesPerRow: TilesPerRow = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 4,
    xl: xlProductsPerRow,
    xxl: xxlProductsPerRow,
  }
  const currentTilesPerRow = tilesPerRow[breakpoint]
  return currentTilesPerRow
}
