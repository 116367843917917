/* istanbul ignore file */
import React, { FC } from 'react'
import { Checkbox, FormControlLabel } from '@moonpig/launchpad-forms'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Box } from '@moonpig/launchpad-components'
import type { AllFilter as AllFilterType } from '../../../../services/types/services'
import { useFindLocaleText } from '../../../../text-localisation'
import { useSearchStore } from '../../../../store/SearchStore'

const StyledFilterOption = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${s({
    pl: 3,
    pr: 8,
  })}
`

type Props = {
  filter: AllFilterType
  onClick: (filter: AllFilterType, select: boolean) => void
}

export const AllFilter: FC<Props> = ({ filter, onClick }) => {
  const localiseText = useFindLocaleText()
  const { id, label, isSelected } = filter
  const allCategoryFilters = useSearchStore(store => store.allCategoryFilters)
  const isChecked = isSelected || allCategoryFilters.includes(id)
  const allLabel = localiseText('find.all', { label })

  return (
    <StyledFilterOption>
      <FormControlLabel
        data-testid="filter-option"
        label={allLabel}
        width="100%"
        key={id}
      >
        <Checkbox
          onChange={() => onClick(filter, !isChecked)}
          checked={isChecked}
          value={id}
        />
      </FormControlLabel>
    </StyledFilterOption>
  )
}
