import React, { FC } from 'react'
import { Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'

const StyledText = styled(Text)`
  ${s({
    pl: 6,
    py: 3,
    display: 'block',
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
    bgcolor: 'colorBackground02',
    borderBottom: 1,
    borderColor: 'colorBorder03',
  })}
`

export const FilterHeader: FC<{ label: string }> = ({ label }) => (
  <StyledText data-testid="filter-header">{label}</StyledText>
)
