import { ApplicableFilter } from '../../services/types/services'
import {
  persistFilterSelection,
  isPersistedFilter,
} from './persistFilterSelection'

export const updateSelectedFilters = ({
  selectedFilters,
  newFilter,
  select,
}: {
  selectedFilters: ApplicableFilter[]
  newFilter: ApplicableFilter
  select: boolean
}): ApplicableFilter[] => {
  const updatedSelectedFilters = [...selectedFilters]
  const filterIndex = updatedSelectedFilters.findIndex(
    filter => filter.id === newFilter.id,
  )

  if (!select && filterIndex !== -1) {
    updatedSelectedFilters.splice(filterIndex, 1)
  } else if (select && filterIndex === -1) {
    updatedSelectedFilters.push(newFilter)
  }

  if (isPersistedFilter(newFilter)) {
    persistFilterSelection(newFilter, select)
  }

  return updatedSelectedFilters
}
