import { Box } from '@moonpig/launchpad-components'
import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useExperiment } from '@moonpig/web-core-experiments'
import { useIsMobile } from '../../../utils'
import { HEADER_HEIGHT_PX } from '../constants'

const StyledFiltersCarouselBox = styled(Box)<{
  isSticky: boolean
  useDropdownFilters: boolean
}>`
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  top: -1px;
  z-index: 500;
  ${breakpoint('md')} {
    top: 0px;
  }
  ${breakpoint('xl')} {
    top: ${HEADER_HEIGHT_PX};
  }

  ${({ useDropdownFilters }) =>
    s({
      boxShadow: useDropdownFilters
        ? 'none'
        : '0px 4px 4px -4px rgba(0, 32, 77, 0.15)',
    })}
`

export const FiltersCarouselStickyWrapper: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const isMobile = useIsMobile()
  const [isSticky, setIsSticky] = useState(false)
  const useDropdownFilters =
    useExperiment('search-use-dropdown-filters')?.toLocaleLowerCase() ===
    'enabled'

  useEffect(() => {
    let prevScrollDepth = window.scrollY
    let thresholdLimitUp = window.scrollY
    let thresholdLimitDown = window.scrollY

    const scrollEvent = () => {
      const currentScrollDepth = window.scrollY
      if (
        prevScrollDepth > currentScrollDepth ||
        (currentScrollDepth > 300 && isMobile)
      ) {
        thresholdLimitDown = currentScrollDepth

        if (
          thresholdLimitUp - 70 > currentScrollDepth &&
          (currentScrollDepth > 300 || isMobile)
        ) {
          setIsSticky(true)
          thresholdLimitUp = currentScrollDepth
        }
      }

      if (
        prevScrollDepth < currentScrollDepth ||
        isSticky ||
        (currentScrollDepth < 300 && !isMobile)
      ) {
        thresholdLimitUp = currentScrollDepth

        /* istanbul ignore next */
        if (
          thresholdLimitDown < currentScrollDepth ||
          (currentScrollDepth < 300 && !isMobile)
        ) {
          thresholdLimitDown = currentScrollDepth
          setIsSticky(false)
        }
      }

      prevScrollDepth = currentScrollDepth
    }

    window.addEventListener('scroll', scrollEvent)

    return () => window.removeEventListener('scroll', scrollEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledFiltersCarouselBox
      data-testid={'sticky-filters-carousel'}
      isSticky={isSticky}
      useDropdownFilters={useDropdownFilters}
    >
      {children}
    </StyledFiltersCarouselBox>
  )
}
