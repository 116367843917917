import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import { styled } from '@moonpig/launchpad-utils'
import {
  DrawerContent,
  DrawerOverlay,
  DrawerWrapper,
  DrawerHeader,
} from './components'
import { HeaderElements } from './components/DrawerHeader'
import { DrawerFooter } from './components/DrawerFooter'

const StyledScrollableContent = styled.div<{
  maxHeight: number
}>`
  overflow-y: auto;
  position: relative;
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  flex: 1;
`

type Props = {
  /** The title of the drawer */
  title?: string
  /** The aria label value for the drawer's content */
  ariaLabel: string
  /** Optionally pass a header configuration */
  header?: {
    left?: HeaderElements
    right?: HeaderElements
  }
  /** Optionally pass a footer component to the drawer */
  footer?: JSX.Element
  /** Determines the anchor position of the drawer */
  anchor?: 'top' | 'bottom' | 'left' | 'right'
  /** Optionally pass a ref to the trigger button so it can receive focus after the drawer is closed */
  triggerRef?: React.RefObject<HTMLElement>
  /** The offset number of pixels */
  offset?: number
  /** Determines if the drawer is visible */
  isOpen: boolean
  /** The aria label value for the drawer's content */
  onClosed: () => void
}

export const Drawer: FC<PropsWithChildren<Props>> = ({
  title,
  ariaLabel,
  header = { right: 'close-button' },
  footer,
  isOpen,
  onClosed,
  anchor,
  triggerRef,
  offset = 80,
  children,
}) => {
  const [isClosing, setClosing] = useState(false)
  const isOpenMemo = useRef(false)
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const [maxContentHeight, setMaxContentHeight] = useState<number>(0)

  const className = `${anchor || 'right'} ${isClosing ? 'close' : 'open'}`

  const handleClosing = () => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      onClosed()
    } else {
      setClosing(true)
    }
  }

  const handleClose = () => {
    if (isClosing) {
      onClosed()
      setClosing(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      isOpenMemo.current = true
    }

    if (!isOpen && isOpenMemo.current && triggerRef?.current) {
      isOpenMemo.current = false
      triggerRef.current.focus()
    }
  }, [isOpen, triggerRef])

  useEffect(() => {
    const onKeyUp = ({ key }: KeyboardEvent) => {
      if (key === 'Escape' && isOpen) {
        handleClosing()
      }
    }
    window.addEventListener('keyup', onKeyUp)

    return () => window.removeEventListener('keyup', onKeyUp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0
    const footerHeight = footerRef.current?.offsetHeight || 0
    setMaxContentHeight(
      window.innerHeight - offset - headerHeight - footerHeight,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <DrawerWrapper isOpen={isOpen}>
      {isOpen && <RemoveScrollBar />}
      <DrawerOverlay
        className={className}
        onClick={handleClosing}
        data-testid="drawer-overlay"
      />
      <DrawerContent
        data-testid="drawer-content"
        className={className}
        onAnimationEnd={handleClose}
        role="dialog"
        aria-label={ariaLabel}
        aria-modal={isOpen}
        offset={offset}
      >
        <DrawerHeader
          handleClosing={handleClosing}
          headerElements={header}
          title={title}
          headerRef={headerRef}
        />
        <StyledScrollableContent maxHeight={maxContentHeight}>
          {children}
        </StyledScrollableContent>
        <DrawerFooter footerRef={footerRef} footer={footer} />
      </DrawerContent>
    </DrawerWrapper>
  )
}
