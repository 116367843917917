import React, { FC } from 'react'
import {
  Box,
  Flex,
  Heading,
  ScreenReaderOnly,
  Text,
} from '@moonpig/launchpad-components'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { useFindLocaleText } from '../../text-localisation'

type HeaderProps = {
  totalCount: number
  title: string
}

const BREAKPOINT = 'md'

const StyledGalleryHeader = styled(Flex)`
  flex-direction: column;
  line-height: 1;

  ${breakpoint(BREAKPOINT)} {
    align-items: center;
    flex-direction: row;
    line-height: 0.8;
  }
`
export const Header: FC<HeaderProps> = ({ totalCount, title }) => {
  const [state] = useProductListingPageContext()
  const { loading } = state
  const t = useFindLocaleText()
  return (
    <StyledGalleryHeader>
      {
        <Box mr={3} mb={6}>
          <Heading
            display="inline"
            level="h1"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {title}
          </Heading>
          {!loading && (
            <Text
              data-testid="web-find-total-product-count"
              typography={{
                xs: 'typeMobileDisplay04',
                md: 'typeDesktopDisplay05',
              }}
              color="colorTextBody"
            >
              &nbsp;{`(${totalCount})`}
              <ScreenReaderOnly>
                {t('find.gallery_title_results_found')}
              </ScreenReaderOnly>
            </Text>
          )}
        </Box>
      }
    </StyledGalleryHeader>
  )
}
