import { ApplicableFilter, FilterItem } from '../../services/types/services'

export const updateAllCategoryFilters = ({
  selectedFilters,
  categoryFilters,
  select,
}: {
  selectedFilters: ApplicableFilter[]
  categoryFilters: FilterItem[]
  select: boolean
}): ApplicableFilter[] => {
  const updatedSelectedFilters = [...selectedFilters]

  categoryFilters.forEach(filter => {
    if (filter.__typename === 'Filter') {
      const filterIndex = updatedSelectedFilters.findIndex(
        selectedFilter => selectedFilter.id === filter.id,
      )

      if (!select) {
        if (filterIndex !== -1) {
          updatedSelectedFilters.splice(filterIndex, 1)
        }
      } else if (filterIndex === -1) {
        updatedSelectedFilters.push(filter)
      }
    }
  })

  return updatedSelectedFilters
}
