import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { PrimaryButton } from '@moonpig/launchpad-components'
import { IconSystemChevronUp } from '@moonpig/launchpad-assets'
import {
  createGenericSelectContentEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { useFindLocaleText } from '../../text-localisation'

type ScrollToTopProps = {
  showScrollToTop: boolean
}

type ScrollToTopButtonProps = ScrollToTopProps & { displayState: boolean }

const TRANSITION_TIME = 150

const StyledPrimaryButton = styled(PrimaryButton)<ScrollToTopButtonProps>`
  ${s({ p: 5, mr: 5, mb: 5 })}
  display: flex;
  margin-left: auto;
  opacity: ${({ showScrollToTop }) => (showScrollToTop ? `1` : `0`)};
  transition: opacity ${TRANSITION_TIME}ms ease-in-out;
  right: ${({ displayState }) => (displayState ? '12px' : '-100px')};
  pointer-events: auto;
`

export const ScrollToTop: FC<ScrollToTopProps> = ({ showScrollToTop }) => {
  const [display, setDisplay] = React.useState(false)
  const localiseText = useFindLocaleText()

  React.useEffect(() => {
    if (showScrollToTop) {
      setDisplay(true)
    } else {
      setTimeout(() => {
        setDisplay(false)
      }, TRANSITION_TIME)
    }
  }, [showScrollToTop, setDisplay])
  return (
    <StyledPrimaryButton
      showScrollToTop={showScrollToTop}
      displayState={display}
      type="button"
      aria-hidden={!display}
      aria-label={localiseText('find.scroll_to_the_top_of_the_viewport')}
      tabIndex={display ? 0 : -1}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        trackGAEvent(
          createGenericSelectContentEvent({
            eventAction: 'utility links',
            eventCategory: 'navigation',
            eventLabel: 'jump to top',
          }),
        )
      }}
    >
      <IconSystemChevronUp width="28" height="28" />
    </StyledPrimaryButton>
  )
}
