import React, { forwardRef, PropsWithChildren } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { CarouselItem as CarouselElement } from '@moonpig/launchpad-components'
import { CAROUSEL_ITEM_HEIGHT_PX } from '../constants'

const StyledCarouselItem = styled(CarouselElement)`
  height: ${CAROUSEL_ITEM_HEIGHT_PX};
  overflow: hidden;
`

export const CarouselItem = forwardRef<
  HTMLLIElement,
  PropsWithChildren<unknown>
>(({ children }, ref) => (
  <StyledCarouselItem tabIndex={-1} ref={ref}>
    {children}
  </StyledCarouselItem>
))
