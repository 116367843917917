import { getBrowserCookies, setBrowserCookie } from '@moonpig/web-core-cookies'
import { PERSISTED_FILTERS } from '@moonpig/web-core-brand/config'
import { ApplicableFilter } from '../../services/types/services'

export const isPersistedFilter = (filter: ApplicableFilter) => {
  return Boolean(PERSISTED_FILTERS[filter.id])
}

export const persistFilterSelection = (
  filter: ApplicableFilter,
  select: boolean,
) => {
  const filterValue = PERSISTED_FILTERS[filter.id]

  if (filterValue) {
    setBrowserCookie(filterValue, select.toString(), {
      path: '/',
    })
  }
}

export const getPersistedFilters = () => {
  const cookies = getBrowserCookies()
  return Object.keys(PERSISTED_FILTERS).reduce((acc, filterId) => {
    if (cookies[PERSISTED_FILTERS[filterId]] === 'true') {
      acc.push(filterId)
    }
    return acc
  }, [] as string[])
}
