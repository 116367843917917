import { createLoadFavourites } from './favourites/createLoadFavourites'
import { createLoadFilters } from './filters/createLoadFilters'
import { createRecommendations } from './recommendations/createRecommendations'
import { createSearch } from './search/createSearch'
import { AppContext } from './types/context'
import { SearchService } from './types/services'

export const createServices: (
  context: AppContext,
) => SearchService = context => {
  return {
    search: createSearch(context),
    loadRecommendations: createRecommendations(context),
    loadFavourites: createLoadFavourites(context),
    loadFilters: createLoadFilters(context),
  }
}
