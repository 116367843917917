import { DepartmentCount, SearchProduct } from '../../services'
import { SearchSortOrder } from '../../services/types/services'

export const reducer = (
  state: SearchProductState,
  action: Action,
): SearchProductState => {
  switch (action.type) {
    case 'PRODUCTS_PAGE_LOADED': {
      return {
        ...state,
        products: [
          ...(action.isPrevious ? action.products : []),
          ...state.products,
          ...(!action.isPrevious ? action.products : []),
        ],
        topPage: action.isPrevious ? action.page : state.topPage,
        bottomPage: action.isPrevious ? state.bottomPage : action.page,
        lastLoadedPage: action.page,
        departmentCounts:
          action.page === 1 ? action.departmentCounts : state.departmentCounts,
        paging: false,
        loading: false,
        loadedMoreProducts: true,
      }
    }
    case 'PRODUCTS_RELOADED':
      return {
        ...state,
        products: action.products,
        suggestions: action.suggestions,
        departmentCounts: action.departmentCounts,
        supportedSortOrders: action.supportedSortOrders,
        count: action.totalCount,
        paging: false,
        loading: false,
        loadedMoreProducts: false,
        topPage: 1,
        bottomPage: 1,
        lastLoadedPage: 1,
      }
    case 'PRODUCTS_PAGING':
      return {
        ...state,
        paging: true,
        loading: false,
      }
    case 'PRODUCTS_RELOADING':
      return {
        ...state,
        paging: false,
        loading: true,
      }
    /* istanbul ignore next */
    default:
      return state
  }
}

type SearchProductState = {
  products: SearchProduct[]
  suggestions: SearchProduct[]
  departmentCounts: DepartmentCount[]
  supportedSortOrders: SearchSortOrder[]
  loading: boolean
  paging: boolean
  loadedMoreProducts: boolean
  topPage: number
  bottomPage: number
  lastLoadedPage: number
  count: number
  totalClickRankDocumentCount: number
}

type PageLoadedAction = {
  type: 'PRODUCTS_PAGE_LOADED'
  page: number
  products: SearchProduct[]
  departmentCounts: DepartmentCount[]
  isPrevious: boolean
}

type PageReloadedAction = {
  type: 'PRODUCTS_RELOADED'
  products: SearchProduct[]
  suggestions: SearchProduct[]
  departmentCounts: DepartmentCount[]
  supportedSortOrders: SearchSortOrder[]
  totalCount: number
}

type PagePagingAction = {
  type: 'PRODUCTS_PAGING'
}

type PageReloadingAction = {
  type: 'PRODUCTS_RELOADING'
}

export type Action =
  | PageLoadedAction
  | PageReloadedAction
  | PageReloadingAction
  | PagePagingAction
