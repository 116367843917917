import { SearchFiltersFacet } from '../../pages/GalleryPage/types'

export const filtersAreEqual = (
  first?: SearchFiltersFacet,
  second?: SearchFiltersFacet,
): boolean => {
  if (!first || !second) {
    return !!first === !!second
  }

  const equal: boolean =
    first.facetKey === second.facetKey &&
    first.group === second.group &&
    first.userApplied === second.userApplied

  return equal
}
