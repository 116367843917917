import { Reducer } from 'react'
import { RecentlyViewedProduct } from './types'
import { addToRecentlyViewed, initialise } from './actions'
import { mapRecentlyViewed } from './mapRecentlyViewed'
import type { State } from './types'

const MAX_RECENTLY_VIEWED_PRODUCTS = 10

const moveFoundItemToFrontOfList = (
  recentlyViewedProducts: RecentlyViewedProduct[],
  recentlyViewedProduct: RecentlyViewedProduct,
) => {
  const foundProduct = recentlyViewedProducts.find(
    p => p.id === recentlyViewedProduct.id,
  )
  if (foundProduct) {
    const foundIndex = recentlyViewedProducts.indexOf(foundProduct)
    recentlyViewedProducts.splice(foundIndex, 1)
  }
  return [recentlyViewedProduct, ...recentlyViewedProducts]
}

export const reducer: Reducer<
  State,
  ReturnType<typeof addToRecentlyViewed> | ReturnType<typeof initialise>
> = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_RECENTLY_VIEWED': {
      const { recentlyViewedProducts } = state
      const { product, region } = action
      const recentlyViewedProduct = mapRecentlyViewed({ product, region })

      const productsToStore = moveFoundItemToFrontOfList(
        recentlyViewedProducts,
        { id: recentlyViewedProduct.id, url: recentlyViewedProduct.url },
      )

      if (productsToStore.length > MAX_RECENTLY_VIEWED_PRODUCTS) {
        productsToStore.pop()
      }

      return {
        recentlyViewedProducts: productsToStore,
      }
    }
    case 'INITIALISE':
      return {
        recentlyViewedProducts: action.recentlyViewedProducts,
      }
    /* istanbul ignore next */
    default:
      return state
  }
}
