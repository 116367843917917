import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'
import { GALLERY_PAGE_SIZE, PARENT_DEPARTMENTS } from '../../constants'
import { mapProduct } from '../helpers/mapper'
import { runGraphQLQuery } from '../helpers/runGraphQLQuery'
import { AppContext } from '../types/context'
import {
  DepartmentCount,
  SearchProduct,
  SearchRequest,
  SearchRequestContext,
  SearchRequestResponse,
  SearchSortOrder,
} from '../types/services'
import { extractDepartmentCounts } from './extractDepartmentCounts'
import { transformFiltersToGroupedFilters } from '../helpers/groupFilters'
import { ProductSearchGQL } from './query'
import {
  SearchServiceProductSearchQuery,
  SearchServiceProductSearchQueryVariables,
} from './__generated__/query'

const ALL_GIFTS_INCLUDING_VOUCHERS = [
  'GIFT_VOUCHERS',
  'ALL_GIFTS',
] as DepartmentsEnum[]

const getHasAugmentedReality = (search: string): boolean => {
  let hasAugmentedReality = false

  // Temporary measure - video flow currently using the hasAugmentedReality field
  const videoExperienceSearchTerm = 'video-experience'

  hasAugmentedReality = !!search && search.includes(videoExperienceSearchTerm)

  return hasAugmentedReality
}

const addIndexes = (
  products: Omit<SearchProduct, 'productIndex'>[],
  startIndex: number,
) => {
  return products.map((x, i) => {
    return { ...x, productIndex: startIndex + i }
  })
}

const search: (
  context: AppContext,
  searchRequest: SearchRequestContext,
) => Promise<SearchRequestResponse> = async (
  { client, features, options },
  {
    searchTerm,
    page,
    filters,
    departments,
    sponsoredProducts,
    promotionId,
    sortOrder,
  },
) => {
  const [secondDepartment, thirdDepartment] = PARENT_DEPARTMENTS.filter(
    parentDepartment => !departments.includes(parentDepartment),
  ).map(x => [x])

  const {
    excludeDirectSmile,
    showCollageMaker,
    cardVariants,
    experimentValues,
    showGiftVouchers,
  } = features
  const {
    payPerClickFiltering,
    defaultSortOrder,
    shouldReturnSuggestions,
    shouldSearchOnAllParentDepartments,
    platform,
  } = options

  const filtersFor = (queryDepartments: DepartmentsEnum[]) => {
    return {
      excludeDirectSmile,
      hasAugmentedReality: getHasAugmentedReality(searchTerm),
      ...(payPerClickFiltering && { onlyPPCResults: payPerClickFiltering }),
      ...(promotionId && { promotionId }),
      ...(excludeDirectSmile && { excludeDirectSmile }),
      ...(showCollageMaker && { hasCollageMaker: true }),
      groupedFacets: transformFiltersToGroupedFilters(filters),
      ...(((queryDepartments?.includes('ALL_CARDS' as DepartmentsEnum) ??
        false) ||
        queryDepartments?.includes('GREETING_CARDS' as DepartmentsEnum) ||
        false) && {
        variants: cardVariants,
      }),
    }
  }

  const offset = page * GALLERY_PAGE_SIZE - GALLERY_PAGE_SIZE
  const result = await runGraphQLQuery<
    SearchServiceProductSearchQuery,
    SearchServiceProductSearchQueryVariables,
    {
      products: SearchProduct[]
      suggestions: SearchProduct[]
      totalCount: number
      departments: DepartmentCount[]
      supportedSortOrders: SearchSortOrder[]
      totalClickRankDocumentCount: number
    }
  >({
    client,
    query: ProductSearchGQL,
    variables: {
      searchTerm,
      offset,
      limit: GALLERY_PAGE_SIZE,
      department:
        showGiftVouchers && departments.includes('ALL_GIFTS' as DepartmentsEnum)
          ? ALL_GIFTS_INCLUDING_VOUCHERS
          : departments,
      filters: filtersFor(departments),
      sponsoredProducts,
      returnSuggestions: shouldReturnSuggestions && page === 1,
      searchOnAllParentDepartments:
        shouldSearchOnAllParentDepartments && page === 1,
      secondDepartment: secondDepartment || [],
      secondProductSearchFilters: filtersFor(secondDepartment),
      thirdDepartment: thirdDepartment || [],
      thirdProductSearchFilters: filtersFor(thirdDepartment),
      sortOrder: sortOrder || defaultSortOrder,
      experimentValues,
      platform,
      nbaAlgorithm: undefined,
    },
    extractResult: data => {
      const products = data.productSearch.products.map(product =>
        mapProduct(product, sponsoredProducts),
      )
      const suggestions =
        data.productSearch.suggestions?.map(product =>
          mapProduct(product, sponsoredProducts),
        ) || []

      return {
        products: addIndexes(products, offset),
        suggestions: addIndexes(suggestions, 0),
        totalCount: data.productSearch.count,
        spellingCorrection:
          data?.productSearch?.spellingCorrection || undefined,
        departments: extractDepartmentCounts({
          departments: [
            ...(secondDepartment || []),
            ...(thirdDepartment ?? []),
          ],
          data,
        }),
        supportedSortOrders: data.productSearch.supportedSortOrders,
        totalClickRankDocumentCount:
          data.productSearch.totalClickRankDocumentCount,
      }
    },
  })

  return {
    ...result,
    page,
  }
}

export const createSearch = (context: AppContext): SearchRequest => {
  return async request => {
    return search(context, request)
  }
}
